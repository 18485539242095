import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Observable, Observer } from 'rxjs';
import { environment } from '../../../environments/environment';

export enum Action {
  JOINED,
  LEFT,
  RENAME,
}

// Socket.io events
export enum Event {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
}

const SERVER_URL = `${environment.backendBaseURL}/roadside-plus`;
@Injectable({ providedIn: 'root' })
export class SocketService {
  private socket: Socket;

  // setupSocketConnection(url: string = '') {
  //   this.socket = io(url);
  //   this.socket.on('connect', () => {
  //     console.log('SOCKET | CONNECTED');
  //   });
  // }

  constructor(namespace?: string) {
    this.initSocket(`${SERVER_URL}${namespace}`);
  }

  public initSocket(uri: string = ''): void {
    this.socket = io(uri);
    this.socket.on('connect', () => {
      console.log('SOCKET | CONNECTED');
    });
  }

  public onEvent(event: string = 'message'): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      this.socket.on(event, (data: any) => observer.next(data));
    });
  }
}
