import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./core/core.module').then(m => m.CoreModule),
  },
  {
    path: 'service-calls',
    data: { breadcrumb: 'Service Calls' },
    loadChildren: () => import('./service-calls/service-calls.module').then(m => m.ServiceCallsModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./settings/user-management/user.module').then(m => m.UserModule),
  },
  {
    path: 'fleet-info',
    data: { breadcrumb: 'Fleet Info' },
    loadChildren: () => import('./settings/fleet-management/fleet-info.module').then(m => m.FleetInfoModule),
  },
  {
    path: 'vehicle-info',
    data: { breadcrumb: 'Vehicle Info' },
    loadChildren: () => import('./settings/vehicle-management/vehicle-info.module').then(m => m.VehicleInfoModule),
  },
  {
    path: 'driver-info',
    loadChildren: () => import('./settings/driver-management/driver.module').then(m => m.DriverModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
