import { Component, OnInit, Output, Input, EventEmitter, ViewChild, ElementRef, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { CustomersService } from '../customers.service';
import { PagedResponse } from 'src/app/shared/services/crud-base.service';
import { Customer } from '../customers.interface';
import { Subscription } from 'rxjs';
import { AutoComplete } from 'primeng/autocomplete';
import { AbstractControl, FormGroup } from '@angular/forms';

interface AutocompleteCustomer extends Customer {
  customerNumberString: string;
}
@Component({
  selector: 'app-customer-picker',
  templateUrl: './customer-picker.component.html',
  styleUrls: ['./customer-picker.component.scss'],
})
export class CustomerPickerComponent {
  @Input() selectedCustomer: AutocompleteCustomer;
  @Input() invalid: boolean;
  @Output() onSelect = new EventEmitter();
  @Input() disabled: boolean;
  @Input() parentForm: FormGroup;
  @Input() controlName: string;
  @Input() submitted: boolean;
  @Input() required = true;
  viewInitialized = false;
  customerSuggestionSubscription: Subscription;
  customers: AutocompleteCustomer[];
  suggestedCustomers: any[];
  displayCustomerLookupDialog: boolean;

  constructor(private customersService: CustomersService, private elRef: ElementRef) { }

  get control(): AbstractControl {
    return this.parentForm && this.controlName ? this.parentForm.get(this.controlName) : null;
  }

  get inputElement(): HTMLInputElement {
    return this.elRef.nativeElement.querySelector('#customer-picker-input') as HTMLInputElement;
  }

  customersAutocomplete(event: any): void {
    if (this.customerSuggestionSubscription) {
      // cancel previous network calls
      this.customerSuggestionSubscription.unsubscribe();
    }
    const queryString = event.query && event.query.trim();
    if (!queryString) {
      this.suggestedCustomers = [];
      return;
    }
    this.customerSuggestionSubscription = this.customersService
      .findMany({
        search: queryString.toLowerCase(),
        searchFields: [
          'customerNumber',
          'name',
          'locationCode',
          'sortName',
          'nationalAccountCustomerNumber',
        ],
        isDeleted: false,
        pageSize: 10,
      })
      .subscribe((res: PagedResponse<Customer>) => {
        this.suggestedCustomers = res.documents;
        this.suggestedCustomers.forEach((c: AutocompleteCustomer) => {
          c.customerNumberString = c.customerNumber.toString();
        });
        this.suggestedCustomers.push({ name: 'Search for more customers', search: true });
      });
  }

  openCustomerLookupDialog(): void {
    this.displayCustomerLookupDialog = true;
  }

  onCustomerAutocompleteSelect(customer: any): void {
    if (customer.search) {
      this.displayCustomerLookupDialog = true;
      this.selectedCustomer = null;
      return;
    }

    this.selectedCustomer = customer;
    this.onSelect.emit(customer);
  }

  onCustomerSelect(customer: AutocompleteCustomer): void {
    this.displayCustomerLookupDialog = false;
    this.selectedCustomer = customer || null;
    this.onSelect.emit(customer);
  }

  clearCustomer() {
    this.selectedCustomer = null;
    this.customersAutocomplete({ query: null });
    this.control ? this.control.setValue(null) : null;
    this.onSelect.emit(null);
    setTimeout(() => {
      this.inputElement && this.inputElement.focus();
    }, 500);
  }
}
