import { appRoles } from './core/constants';
import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { LayoutService } from './layout/layout.service';
import { Layout } from './layout/layout.interface';
import { KeycloakService } from 'keycloak-angular';
import { AuthService } from './core/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { KeycloakProfile } from 'keycloak-js';

// declare google analytics
declare const gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'roadside-plus';
  user: KeycloakProfile;
  dialog: any = { visible: false };
  userRoles: string[];
  username: string;
  constructor(
    private keycloakService: KeycloakService,
    private layoutService: LayoutService,
    private authService: AuthService, // Don't remove this, it loads the token auto watcher;
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
  ) {
    this.dialogWatcher();
  }

  // To track page views with path name
  // Author - Simple B
  ngAfterViewInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && isPlatformBrowser(this.platformId)) {
        if (event instanceof NavigationEnd) {
          gtag('config', 'G-45GZQ8ZL5N',
            {
              page_path: event.urlAfterRedirects,
            },
          );
        }
      }
    });
  }

  async ngOnInit() {
    if (await this.keycloakService.isLoggedIn()) {
      this.userRoles = this.keycloakService.getUserRoles();
      this.user = await this.keycloakService.loadUserProfile();
      this.initLayout();
    }
  }

  initLayout(): void {
    const u = this.user;
    const layout: Layout = {
      topbar: {

        menuItems: [
          {
            label: 'Search',
            icon: 'fas fa-search',
            routerLink: '/service-calls/search',
            visible: this.userRoles.includes(appRoles.customerDriver) ? false : true,
          },
          {
            label: 'Service Call Board',
            icon: 'fas fa-clipboard-list',
            routerLink: '/service-calls/board',
            visible: this.userRoles.includes(appRoles.customerDriver) ? false : true,
          },
          {
            label: 'Warehouse Board',
            icon: 'fas fa-warehouse',
            routerLink: '/service-calls/warehouse-board',
            visible: this.userRoles.includes(appRoles.customerEmployee) ? false : true,
          },
          {
            label: 'New Service Call',
            icon: 'fas fa-plus',
            routerLink: '/service-calls/user',
            visible: this.userRoles.includes(appRoles.customerEmployee) ? true : false,
          },
        ],

        settingsMenu: {
          menuItems: [
            {
              label: 'Settings',
              items: [

                {
                  label: 'Manage Fleets',
                  icon: 'fas fa-clipboard-list',
                  routerLink: '/fleet-info',
                  visible: this.userRoles.includes(appRoles.stmEmployee) ? true : false,
                },
                {
                  label: 'Manage Users',
                  icon: 'fa fa-user-circle-o',
                  routerLink: '/users',
                  visible: this.userRoles.includes(appRoles.stmEmployee) ? true : false,
                },
                {
                  label: 'Manage Vehicles',
                  icon: 'fas fa-plus',
                  routerLink: '/vehicle-info/list',
                  visible: this.userRoles.includes(appRoles.stmEmployee) ? true : false,
                },
                {
                  label: 'Manage Drivers',
                  icon: 'fa fa-id-card-o',
                  routerLink: '/driver-info/list',
                  visible: this.userRoles.includes(appRoles.stmEmployee) ? true : false,
                },
              ],
            },
          ],
        },

        userMenu: {
          displayName: `${u.firstName} ${u.lastName}`,
          menuItems: [
            {
              label: `${u.firstName} ${u.lastName}`,
              icon: 'fas fa-user',
              items: [
                {
                  label: 'Logout',
                  command: () => this.keycloakService.logout(),
                  icon: 'fad fa-sign-out-alt',
                },
              ],
            },
          ],
        },

      },
    };
    this.layoutService.init(layout).subscribe();
  }

  dialogWatcher() {
    this.layoutService.layout.subscribe((layout: Layout) => {
      this.dialog = layout.dialog || { visible: false };
    });
  }
}
