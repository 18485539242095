import { Component, OnInit } from '@angular/core';
import { DriverInfo } from '../model/DriverInfo';
import { DriverService } from '../driver.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {

  display: boolean = false;
  driverList: DriverInfo[];
  selectedData: any = [];
  constructor(protected driverService: DriverService) { }

  ngOnInit() {
    this.bindDrivers();
  }

  // Function to get list of all drivers
  // CreatedBy - Prashant S4
  async bindDrivers() {
    this.driverService.driverListWithFleet().subscribe((listDrivers: DriverInfo[]) => {
      this.driverList = listDrivers.reverse();
    });
  }

  showLocationModal(driverInfo: DriverInfo) {
    const fleetInfo = driverInfo.fleet;
    this.display = true;
    if (fleetInfo && fleetInfo.location) {
      // tslint:disable-next-line: ter-prefer-arrow-callback
      this.selectedData = fleetInfo.location.filter(function (loc) {
        const index = driverInfo.fleetLocations.findIndex(x => x.locationId === loc._id);
        if (index >= 0) {
          return true;
        }
        return false;
      });
    } else {
      this.selectedData = [];
    }
  }

  // Function to close location madal
  closeLocationModal() {
    this.selectedData = [];
    this.display = false;
    this.selectedData = [];
  }

}
