<form class="service-call-form labelColor" [formGroup]="fleetForm" (keydown.enter)="$event.preventDefault()"
  (submit)="addUpdateFleetInfo()">
  <div class="form-box">
    <h2 class="ml7">Fleet Info</h2>
    <div class="p-grid">
      <div class="p-col-12 p-md-12">
        <app-super-input class="form-control ng-untouched ng-pristine ng-valid p-component"
          id="exampleInputEmail1" aria-describedby="emailHelp" label="Fleet Name" [formSubmitted]="submitted"
          icon="fa fa-user" [fc]="fleetForm.controls['fleetName']" maxlength="100">
        </app-super-input>
      </div>
    </div>


    <div class="p-grid">
      <div class="p-col-12 p-md-4">
        <app-super-input class="form-control ng-untouched ng-pristine ng-valid p-component"
          id="exampleInputEmail1" aria-describedby="emailHelp" label="Address" [formSubmitted]="submitted"
          icon="fas fa-street-view" [fc]="fleetForm.controls['fleetAddress']" maxlength="60">
        </app-super-input>
      </div>
      <div class="p-col-12 p-md-4">
        <app-super-input class="form-control ng-untouched ng-pristine ng-valid p-component"
          id="exampleInputEmail1" aria-describedby="emailHelp" label="City" icon="fas fa-city"
          [formSubmitted]="submitted" [fc]="fleetForm.controls['fleetCity']" maxlength="40">
        </app-super-input>
      </div>
      <div class="p-col-12 p-md-4">
        <div class="p-inputgroup">

          <p-autoComplete placeholder="State" [suggestions]="stateSuggestion"
            [formControl]="fleetForm.controls['fleetState']" (completeMethod)="filterStateSearch($event)"
            maxlength="40">
          </p-autoComplete>

          <label *ngIf="submitted && (!fleetForm.controls['fleetState'].valid)"
            [ngClass]="{'invalid-text': submitted && (!fleetForm.controls['fleetState'].valid)}">** State is
            required.</label>
        </div>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col-12 p-md-4">
        <app-super-input class="form-control ng-untouched ng-pristine ng-valid p-component"
          id="exampleInputEmail1" aria-describedby="emailHelp" label="Zip Code" [formSubmitted]="submitted"
          icon="fa fa-map-pin" [fc]="fleetForm.controls['zip']">
        </app-super-input>
      </div>
      <div class="p-col-12 p-md-4">
        <app-super-input class="form-control ng-untouched ng-pristine ng-valid p-component"
          id="exampleInputEmail1" aria-describedby="emailHelp" label="Primary Contact Name" [formSubmitted]="submitted"
          icon="fa fa-user" [fc]="fleetForm.controls['fleetPrimaryName']" maxlength="40">
        </app-super-input>
      </div>
      <div class="p-col-12 p-md-4">
        <app-super-input class="form-control ng-untouched ng-pristine ng-valid p-component"
          id="exampleInputEmail1" aria-describedby="emailHelp" label="Primary Phone" [formSubmitted]="submitted"
          icon="fas fa-phone" [fc]="fleetForm.controls['fleetPrimaryPhone']">
        </app-super-input>
      </div>
    </div>



    <div class="p-grid">
      <div class="form-group ml7">
        <label for="">Same as primary contact </label>
        <p-checkbox name="groupname"
          (onChange)="checkedPOButton(fleetForm.get('fleetPrimaryName').value, fleetForm.get('fleetPrimaryPhone').value,$event)"
          maxlength="40">
        </p-checkbox>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12 p-md-6">
        <app-super-input class="form-control ng-untouched ng-pristine ng-valid p-component"
          id="exampleInputEmail1" aria-describedby="emailHelp" label="PO Contact Name" icon="fa fa-user"
          [formSubmitted]="submitted" [fc]="fleetForm.controls['fleetPOName']" maxlength="40">
        </app-super-input>
      </div>
      <div class="p-col-12 p-md-6">
        <app-super-input class="form-control ng-untouched ng-pristine ng-valid p-component"
          id="exampleInputEmail1" aria-describedby="emailHelp" label="PO Phone" [formSubmitted]="submitted"
          icon="fas fa-phone" [fc]="fleetForm.controls['fleetPOPhone']">
        </app-super-input>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col-12 p-md-12">
        <label>Fleet Notes</label>
        <textarea pInputTextarea maxlength="500" class="input-border" formControlName="fleetNotes" rows="5"
          style="width:100%"></textarea>
        <p *ngIf="display" style="color: red; font-size: 12px;">{{500 - theLength}} characters remaining.</p>
      </div>
    </div>

  </div>

  <app-location-info #itemForm [locationList]="locationList"
    [fleetName]="fleetForm.get('fleetPrimaryName').value" [fleetPhone]="fleetForm.get('fleetPrimaryPhone').value"
    [fleetPOName]="fleetForm.get('fleetPOName').value" [fleetPOPhone]="fleetForm.get('fleetPOPhone').value"
    (created)="onCreateLocations($event)" (updated)="onUpdateLocations($event)"></app-location-info>


  <p-table #dt class="center" [value]="locationList" dataKey="id" [rows]="10"
    [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" styleClass="p-table-customers"
    [paginator]="true" [filterDelay]="0" [globalFilterFields]="['locationInfo','addressState','addressCity']">
    <ng-template pTemplate="caption">
      <div class="pMessageCust">
        <p-message severity="warn" *ngIf="!isAddlocation" text="Please add at least one location."></p-message>
      </div>
      <div class="pMessageInput">
        <input type="text" pInputText size="50" placeholder="Search"
          (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:20vw">
        <button type="button" (click)="itemForm.showModalDialog()" pButton icon="pi pi-external-link"
          class="submitBtn ml" label="Add Location"></button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Location</th>
        <th>Address</th>
        <th>City</th>
        <th>State</th>
        <th>Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-location let-rowIndex="rowIndex">
      <tr>
        <td>{{location.locationInfo}}</td>
        <td>{{location.address}}</td>
        <td>{{location.addressCity}}</td>
        <td>{{location.addressState}}</td>
        <td><a href="#" (click)="$event.preventDefault(); itemForm.edit(location, rowIndex)" class="icon">
            <i class="fa fa-pencil"></i>
          </a>
          <a href="#" (click)="$event.preventDefault(); showDeletePOPUP(location,rowIndex)" class="icon">
            <i class="fa fa-trash"></i></a></td>
      </tr>
      <p-dialog header="Delete Location" [(visible)]="deletePop" [modal]="true" [style]="{width: '30vw'}"
        [baseZIndex]="10000" [draggable]="false" [resizable]="false">
        <p>Are you sure you want to remove this location?</p>
        <ng-template pTemplate="footer">
          <button type="button" pButton icon="pi pi-check" (click)="deletePopLocation()" label="Yes"></button>
          <button type="button" pButton icon="pi pi-times" (click)="deletePop=false" label="No"
            class="p-button-secondary"></button>
        </ng-template>
      </p-dialog>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-location>
      <tr>
        <td colspan="5" class="no-record-msg">No location added yet..!!</td>
      </tr>
    </ng-template>
  </p-table>
  <br>

  <div class="p-grid" style="align-items: center;">
    <div class="p-col-12 p-md-12" style="text-align: center;">
      <button class="btn1 btn btn-danger" type="button" (click)="redirectToList()">Cancel</button>
      <button class="btn2 btn p-button p-button-success ml7" type="button"
        [disabled]="!fleetForm && !isSaving" (click)="addUpdateFleetInfo()">{{formMode}}</button>
    </div>
  </div>
</form>

<p-dialog header="Location Already Selected" [(visible)]="locationNotDelete" [modal]="true" [style]="{width: '40vw'}"
  [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <p>Fleet location already selected in user management field.</p>
</p-dialog>