import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerAdvancedSearchComponent } from './customer-advanced-search/customer-advanced-search.component';
import { SharedModule } from '../shared/shared.module';
import { CustomerPickerComponent } from './customer-picker/customer-picker.component';

@NgModule({
  declarations: [CustomerAdvancedSearchComponent, CustomerPickerComponent],
  imports: [CommonModule, SharedModule],
  exports: [CustomerPickerComponent, CustomerAdvancedSearchComponent],
})
export class CustomersModule {}
