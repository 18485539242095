import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FleetInfoRoutingModule } from './fleet-info-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { FleetInfoComponent } from './fleet-info/fleet-info.component';
import { LocationInfoComponent } from './location-info/location-info.component';
import { LayoutModule } from 'src/app/layout/layout.module';
import { CustomersModule } from '../../customers/customers.module';
import { FleetInfoItemsComponent } from './fleet-info-items/fleet-info-items.component';

@NgModule({
  declarations: [
    FleetInfoComponent,
    LocationInfoComponent,
    FleetInfoItemsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    LayoutModule,
    CustomersModule,
    FleetInfoRoutingModule,
  ],
})
export class FleetInfoModule { }
