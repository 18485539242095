import { Injectable } from '@angular/core';
import { Layout, Dialog } from './layout.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  defaultLayout: Layout = {
    topbar: {
      menuItems: [{ label: 'Please Init Layout' }],
      settingsMenu: {},
      userMenu: { displayName: 'john doe', menuItems: [{ label: '' }] },
    },
    dialog: { visible: false },
  };

  private layoutBehaviorSubject: BehaviorSubject<Layout> = new BehaviorSubject<Layout>(this.defaultLayout);
  layout: Observable<Layout> = this.layoutBehaviorSubject.asObservable();

  constructor() { }

  init(layout: Layout): Observable<Layout> {
    this.layoutBehaviorSubject.next(layout);
    return this.layout;
  }

  updateDialog(dialog: Dialog): Promise<void> {
    const layout = this.layoutBehaviorSubject.getValue();
    layout.dialog = dialog;
    this.layoutBehaviorSubject.next(layout);
    return Promise.resolve();
  }

}
