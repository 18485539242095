<p-dialog header="{{header}}" [(visible)]="displayModal" [style]="{width: '90vw'}" [modal]="true"
  (onHide)="closeDialog()" [baseZIndex]="10100">
  <div style="overflow: overlay;">
    <form [formGroup]="locationForm" (submit)="submit()">
      <div class="p-col-12 p-md-12">
        <div class="p-grid">
          <div class="p-col-12 p-md-12">
            <app-customer-picker
              #customerPicker
              pickerLabel="Select a billing account"
              [disabled]="locationForm.disabled"
              class="customer-lookup"
              [invalid]="submitted && (!locationForm.controls['customerNumber'].valid)"
              [selectedCustomer]="selectedCustomer"
              (onSelect)="onCustomerSelect($event)"
              [parentForm]="locationForm"
              controlName="customerNumber">
            </app-customer-picker>
          </div>
        </div>

        <div class="p-grid">
          <div class="p-col-12 p-md-12">
            <div class="float-right">
              <label class="auto-complete-label" for="float-input">Default service store</label>
              <select (change)="onStoreChanges($event)" id="storeDropDown" formControlName="storeNumber"
                [ngClass]="{'error':(this.locationForm.controls.storeNumber.status == 'INVALID' && submitted)}"
                class="form-select">
                <option value="null" selected disabled>Select Store</option>
                <option value="{{store.storeNumber}}" *ngFor="let store of this.storeList">
                  {{store.name}}</option>
              </select>

            </div>
          </div>
        </div>

        <div class="p-grid">
          <div class="p-col-12 p-md-6">
            <app-super-input
              class="form-control ng-untouched ng-pristine ng-valid p-component"
              id="exampleInputEmail1" aria-describedby="emailHelp" label=" Add Location" [formSubmitted]="submitted"
              icon="fas fa-street-view" [fc]="locationForm.controls['locationInfo']" [isReadonly]="readonly">
            </app-super-input>
          </div>
          <div class="p-col-12 p-md-6">
            <app-super-input
              class="form-control ng-untouched ng-pristine ng-valid p-component"
              id="exampleInputEmail1" aria-describedby="emailHelp" label=" Address/Location" [formSubmitted]="submitted"
              icon="fas fa-street-view" [fc]="locationForm.controls['address']" [isReadonly]="readonly">
            </app-super-input>
          </div>
        </div>

        <div class="p-grid">
          <div class="p-col-12 p-md-6">
            <p-autoComplete placeholder="State" [suggestions]="stateSuggestion"
              [formControl]="locationForm.controls['addressState']" (completeMethod)="filterStateSearch($event)"
              maxlength="40">
            </p-autoComplete>
          </div>
          <div class="p-col-12 p-md-6">
            <app-super-input
              class="form-control ng-untouched ng-pristine ng-valid p-component"
              id="exampleInputEmail1" aria-describedby="emailHelp" label="City" [formSubmitted]="submitted"
              icon="fas fa-city" [fc]="locationForm.controls['addressCity']" [isReadonly]="readonly">
            </app-super-input>
          </div>

        </div>

        <div class="p-grid">
          <div class="p-col-12 p-md-6">
            <app-super-input class="form-control ng-untouched ng-pristine ng-valid p-component"
              id="exampleInputEmail1" aria-describedby="emailHelp" label="Location Code" [formSubmitted]="submitted" icon="fa fa-map-pin" [fc]="locationForm.controls['locationCode']">
            </app-super-input>
          </div>
          <div class="p-col-12 p-md-6">
            <app-super-input class="form-control ng-untouched ng-pristine ng-valid p-component"
              id="exampleInputEmail1" aria-describedby="emailHelp" label="Zip Code" [formSubmitted]="submitted" icon="fa fa-map-pin" [fc]="locationForm.controls['zip']">
            </app-super-input>
          </div>
        </div>

        <div class="form-group ml7">
          <label class="customLable" for="">Same as fleet primary contact info</label>
          <p-checkbox name="groupname" (onChange)="primaryContact($event)"></p-checkbox>
        </div>

        <div class="p-grid">
          <div class="p-col-12 p-md-6">
            <app-super-input
              class="form-control ng-untouched ng-pristine ng-valid p-component"
              id="exampleInputEmail1" maxlength="50" aria-describedby="emailHelp" label="Contact Name" icon="fa fa-user"
              [formSubmitted]="submitted" [fc]="locationForm.controls['contactName']">
            </app-super-input>
          </div>
          <div class="p-col-12 p-md-6">
            <app-super-input
              class="form-control ng-untouched ng-pristine ng-valid p-component"
              id="exampleInputEmail1" aria-describedby="emailHelp" label="Contact Phone" icon="fas fa-phone"
              [formSubmitted]="submitted" [fc]="locationForm.controls['contactPhone']">
            </app-super-input>
          </div>
        </div>

        <div class="form-group ml7">
          <label class="customLable" for="">Same as fleet PO contact info </label>
          <!-- <input type="checkbox" (change)="poContact($event.target.checked)"> -->
          <p-checkbox name="groupname" (onChange)="poContact($event)"></p-checkbox>
        </div>

        <div class="p-grid">
          <div class="p-col-12 p-md-6">
            <app-super-input
              class="form-control ng-untouched ng-pristine ng-valid p-component"
              id="exampleInputEmail1" aria-describedby="emailHelp" label="PO Contact Name" [formSubmitted]="submitted"
              icon="fa fa-user" maxlength="50" [fc]="locationForm.controls['poContactName']">
            </app-super-input>
          </div>
          <div class="p-col-12 p-md-6">
            <app-super-input
              class="form-control ng-untouched ng-pristine ng-valid p-component"
              id="exampleInputEmail1" aria-describedby="emailHelp" label="PO Contact Phone" [formSubmitted]="submitted"
              icon="fas fa-phone" [fc]="locationForm.controls['poContactPhone']">
            </app-super-input>
          </div>
        </div>


        <div class="p-grid">
          <div class="p-col-12 p-md-12">
            <label class="customLable">Location Notes</label>
            <textarea pInputTextarea maxlength="200" class="input-border" formControlName="locationNotes" rows="5"
              style="width:100%"></textarea>
          </div>
        </div>

        <div class="p-grid mb30" style="align-items: center; margin-bottom:30px ; ">
          <div class="p-col-12 p-md-12" style="text-align: center;">
            <button class="btn1 btn btn-danger" type="button" (click)="redirectToFleetForm()">Cancel</button>
            <button class="btn btn-success-custom" type="button" [disabled]="!locationForm && !isSaving"
              (click)="submit()">{{formMode}}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</p-dialog>
