export const keycloakDev = {
  url: 'https://id.stmtires.com/auth',
  realm: 'STM_Dev',
  clientId: 'STM_Roadside_Plus',
  customerDriverRoleId: '5e452aa5-f033-4a3d-af8e-885117301b5a',
  customerEmployeeRoleId: '6a21d582-c090-48e9-be60-77d5a24999be',
  ClientKeyCloakId: '8d4ecd9d-57a6-4bd2-8fab-f22f90c92e98',
};

export const keycloakProd = {
  url: 'https://id.stmtires.com/auth',
  realm: 'STM_Prod',
  clientId: 'STM_Roadside_Plus',
  ClientKeyCloakId: '',
};
