import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudBaseService } from '../shared/services/crud-base.service';
import { Customer, CustomerDefaultFormValue, CustomerVendorNotes } from './customers.interface';
import { QueryParams } from '../shared/services/base-http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomersService extends CrudBaseService<Customer> {
  protected urlPath: string = 'customers';

  constructor(protected http: HttpClient) {
    super();
  }

  getVendorNotes(params?: QueryParams): Observable<CustomerVendorNotes> {
    const url = `${this.url}vendor-notes`;
    return this.http.get<CustomerVendorNotes>(url, { params: this.getHttpParams(params) });
  }

  getDefaultFormValues(params?: QueryParams): Observable<CustomerDefaultFormValue[]> {
    const url = `${this.url}default-form-values`;
    return this.http.get<CustomerDefaultFormValue[]>(url, { params: this.getHttpParams(params) });
  }
}
