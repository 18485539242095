import { Observable, of } from 'rxjs';
import { map, mergeMap, startWith, debounceTime } from 'rxjs/operators';
import { SocketService } from './socket.service';
import { BaseHttpService,  QueryParams } from './base-http.service';

export interface PagedResponse<T> {
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  documents: T[];
}

export interface CRUDObject {
  _id?: string;
}

/**
 * Base CRUD Service
 * Provides basic functionality Create, Read, Update, Delete (CRUD) of a model out of the box
 */
export abstract class CrudBaseService<T extends CRUDObject> extends BaseHttpService {
  protected cache: boolean = false;
  protected useSockets: boolean = true;
  private socket: SocketService;
  private cachedResults: { [url: string]: Observable<T> } = {};

  constructor(protected socketNamespaceName = '') {
    super();
    if (this.socketNamespaceName) {
      this.socket = new SocketService(this.socketNamespaceName);
    }
  }

  /**
   * Lists objects (paged results)
   * @param params {QueryParams} query parameters to pass to url
   * @returns {Observable<PagedResponse<T>>}
   */
  findMany(params?: QueryParams): Observable<PagedResponse<T>> {
    return this._http.get<PagedResponse<T>>(this.url, { params: this.getHttpParams(params) });
  }

  /**
   * List objects (paged results), and auto update from a socket connection
   * @param params {QueryParams} query parameters to pass to url
   * @returns {Observable<PagedResponse<T>>}
   */
  findManyRealtime(params?: QueryParams): Observable<PagedResponse<T>> {
    const findMany = this.findMany(params);
    return this.socket.onEvent().pipe(
      startWith(() => findMany),
      mergeMap(() => findMany),
    );
  }

  /**
   * Read a single object by id
   * @param id {string}
   * @param params {QueryParams} query parameters to pass to url
   * @returns {Observable<T>}
   */
  findOne(id: string, params?: QueryParams): Observable<T> {
    const url = `${this.url}${id}`;
    if (this.cache) {
      if (this.cachedResults[<string>url]) {
        return this.cachedResults[<string>url];
      }
    }

    return this._http.get<T>(url, { params: this.getHttpParams(params) }).pipe(
      map((result: T) => {
        if (this.cache) {
          this.cachedResults[<string>url] = of(result);
        }
        return result;
      }),
    );
  }

  /**
   * Read a single object by id and auto update from a socket connection
   * @param id {string}
   * @param params {QueryParams} query parameters to pass to url
   * @returns {Observable<T>}
   */
  // TODO: check to make sure socket message has to do with the same record.
  findOneRealtime(id: string, params?: QueryParams): Observable<T> {
    const findOne = this.findOne(id, params);
    return this.socket.onEvent().pipe(
      startWith(() => findOne),
      mergeMap(() => findOne),
      debounceTime(5000),
    );
  }

  /**
   * Create a new object
   * @param newObject {T}
   * @param params {QueryParams} query parameters to pass to url
   * @returns
   */
  create(newObject: T, params?: QueryParams): Observable<T> {
    return this._http.post<T>(this.url, newObject, { params: this.getHttpParams(params) });
  }

  /**
   * Update a single object
   * @param objectToUpdate {T} should extend CRUDObject and have an _id property
   * @param params {QueryParams} query parameters to pass to url
   * @returns
   */
  update(objectToUpdate: T, params?: QueryParams): Observable<T> {
    const url = `${this.url}${objectToUpdate._id}`;
    return this._http.put<T>(url, objectToUpdate, { params: this.getHttpParams(params) });
  }

  /**
   * Delete a single object
   * @param objectToDelete {T} should extend CRUDObject and have an _id property
   * @param params {QueryParams} query parameters to pass to url
   * @returns
   */
  delete(objectToDelete: T, params?: QueryParams): Observable<T> {
    const url = `${this.url}${objectToDelete._id}`;
    return this._http.delete<T>(url, { params: this.getHttpParams(params) });
  }
}
