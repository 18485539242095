import { PagedResponse } from 'src/app/shared/services/crud-base.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerPickerComponent } from 'src/app/customers/customer-picker/customer-picker.component';
import { Customer } from 'src/app/customers/customers.interface';
import { FormMode, usStates } from 'src/app/core/constants';
import { ToastService } from 'src/app/layout/toast.service';
import { ServiceCall } from 'src/app/service-calls/service-call.interface';
import { phoneNumberValidator } from 'src/app/shared/validators/phone-validator';
import { StoreService } from 'src/app/stores/stores.service';
import { FleetInfoService } from '../fleet-info.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from 'src/app/stores/stores.interface';

const LOCATION_TOAST_MESSAGES = {
  add: {
    addSuccess: {
      summary: 'Added',
      detail: 'Location Added.',
    },
  },
  update: {
    addSuccess: {
      summary: 'Updated',
      detail: 'Location Updated.',
    },
  },
};
@Component({
  selector: 'app-location-info',
  templateUrl: './location-info.component.html',
  styleUrls: ['./location-info.component.scss'],
})
export class LocationInfoComponent implements OnInit {

  locationForm: FormGroup;
  submitted: boolean = false;
  readonly: boolean = true;
  displayModal = false;
  showNoCustomerSelectedMsg: boolean;
  accountCustomer: any;
  isSaving: boolean;
  formMode = FormMode.Add;
  editableIndex: number;
  closable = false;
  checkedInfo: any;
  locationsList: any = [];
  message: any;
  fleetId: string;
  header: string;

  private serviceCallValue: ServiceCall;
  @Input() get serviceCall() { return this.serviceCallValue; }
  @Input() selectedCustomer: Customer;
  @Input() fleetName: string;
  @Input() fleetPhone: number;
  @Input() fleetPOName: string;
  @Input() fleetPOPhone: number;
  @Input() customerLook: boolean;
  @Output() created = new EventEmitter();
  @Output() updated = new EventEmitter();
  @Input() contact: string;
  @Input() locationList = [];

  stateSuggestion = [];
  state = [];

  storeList: (Store & { distance?: string | number })[] = [];
  lat: number = 0;
  lng: number = 0;
  distance: string;

  @ViewChild('customerPicker', { static: false }) customerPicker: CustomerPickerComponent;

  constructor(
    private fb: FormBuilder,
    private toastService: ToastService,
    private storeService: StoreService,
    private fleetinfoservice: FleetInfoService,
    private route: Router,
    private router: ActivatedRoute,
  ) {
    this.buildForm();
  }

  ngOnInit() {
    this.isSaving = false;
    this.fleetId = this.router.snapshot.params.id;
    this.header = this.fleetId ? 'Edit Location' : 'Add Location';
    this.searchState();
    this.getStores();
  }

  // Function for location form controller
  buildForm() {
    this.locationForm = this.fb.group({
      locationInfo: ['', Validators.required],
      address: [''],
      addressCity: [''],
      addressState: [''],
      contactName: [''],
      contactPhone: ['', phoneNumberValidator],
      poContactName: [''],
      poContactPhone: ['', phoneNumberValidator],
      locationNotes: [],
      customerNumber: ['', Validators.required],
      customerLookup: [''],
      storeNumber: '',
      selected: [false],
      userId: '',
      zip: ['', Validators.required],
      locationCode: [''],
    });
  }

  // Function to display content on customer select
  onCustomerSelect(customer: Customer): void {
    this.accountCustomer = customer;
    this.showNoCustomerSelectedMsg = false;
    this.locationForm.get('locationInfo').patchValue(this.accountCustomer.name);
    let address;
    if (this.accountCustomer.address1) {
      address = this.accountCustomer.address1;
    } else {
      if (this.accountCustomer.address2address) {
        if (address) {
          address = `${address} ${this.accountCustomer.address2}`;
        } else {
          address = this.accountCustomer.address2;
        }
      }
    }

    this.locationForm.get('address').patchValue(address);
    this.locationForm.get('addressCity').patchValue(this.accountCustomer.city);
    this.locationForm.get('addressState').patchValue(this.accountCustomer.state);
    this.locationForm.get('contactPhone').patchValue(this.accountCustomer.phone);
    this.locationForm.get('zip').patchValue(this.accountCustomer.zip);
    this.locationForm.get('locationCode').patchValue(this.accountCustomer.locationCode);
    this.locationForm.get('customerNumber').patchValue(this.accountCustomer.customerNumber);

  }

  // Function to display primary contact on checked
  primaryContact(isChecked: boolean) {
    this.checkedInfo = isChecked;
    if (isChecked) {
      this.locationForm.get('contactName').patchValue(this.fleetName);
      this.locationForm.get('contactPhone').patchValue(this.fleetPhone);
    } else {
      this.locationForm.get('contactName').patchValue('');
      this.locationForm.get('contactPhone').patchValue('');
    }
  }

  // Function to display PO contact on checked
  poContact(isChecked: boolean) {
    this.checkedInfo = isChecked;
    if (isChecked) {
      this.locationForm.get('poContactName').patchValue(this.fleetPOName);
      this.locationForm.get('poContactPhone').patchValue(this.fleetPOPhone);
      // tslint:disable-next-line: no-parameter-reassignment
      isChecked = false;
    } else {
      this.locationForm.get('poContactName').patchValue('');
      this.locationForm.get('poContactPhone').patchValue('');
    }
  }

  reset() {
    this.locationForm.reset();
    this.customerPicker.clearCustomer();
  }

  // Function to show modal dialog
  showModalDialog() {
    this.displayModal = true;
    this.locationForm.reset();
  }

  // Function to close dialog of location info
  closeDialog() {
    this.locationForm.reset();
    this.customerPicker.clearCustomer();
    this.locationForm.get('poContactName').patchValue('');
    this.locationForm.get('poContactPhone').patchValue('');
    this.locationForm.get('contactName').patchValue('');
    this.locationForm.get('contactPhone').patchValue('');
  }

  // Function to edit location info
  edit(item, index: number) {
    if (!item.customerLookup.accountSalesmanName) {
      item.customerLookup = JSON.parse(item.customerLookup);
    }

    this.formMode = FormMode.Edit;
    this.editableIndex = index;
    this.displayModal = true;
    this.customerPicker.selectedCustomer = item.customerLookup;
    this.locationForm = this.fb.group({
      id: [item._id],
      locationInfo: [item.locationInfo, Validators.required],
      address: [item.address],
      addressCity: [item.addressCity],
      addressState: [item.addressState],
      contactName: [item.contactName],
      contactPhone: [item.contactPhone == null ? '' : item.contactPhone, phoneNumberValidator],
      poContactName: [item.poContactName],
      poContactPhone: [item.poContactPhone == null ? '' : item.poContactPhone, phoneNumberValidator],
      locationNotes: [item.locationNotes, Validators.maxLength(500)],
      customerNumber: [item.customerLookup.customerNumber],
      customerLookup: [item.customerLookup],
      storeNumber: [item.storeNumber],
      selected: [item.selected],
      userId: [item.userId],
      zip: [item.zip, Validators.required],
      locationCode: [item.locationCode],
    });
    this.locationForm.markAsPristine();
  }

  // Function to display distance on store change
  onStoreChanges(event) {
    const store: any = this.storeList.filter(x => x.storeNumber === event.target.value);
    if (store.length > 0) {
      if (this.lat !== 0 && this.lng !== 0) {
        this.distance = this.getDistanceFromLatLang(store[0].location.lat, store[0].location.lng, this.lat, this.lng);
      }
    }
    return '';
  }

  getDistanceFromLatLang(lat1, lon1, lat2, lon2) {
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
    const φ2 = lat2 * Math.PI / 180;
    const Δ1 = (lat2 - lat1) * Math.PI / 180;
    const Δ2 = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(Δ1 / 2) * Math.sin(Δ1 / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δ2 / 2) * Math.sin(Δ2 / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres

    return (d * (0.6213) / 1000).toFixed(3);
  }

  // Function to get list of all stores
  getStores() {
    this.storeService.findMany().subscribe((storeResponse: PagedResponse<Store>) => {
      this.storeList = [];
      const stores = storeResponse.documents;
      stores.forEach((store) => {
        store.name = `${store.storeNumber} - ${store.name}`;
        this.storeList.push(store);
      });
      if (this.lat !== 0 && this.lng !== 0) {
        this.addDistanceInStore();
      }
    });
  }

  addDistanceInStore() {
    const index = this.storeList.findIndex(x => x.storeNumber === 1);
    let firstStore = this.storeList[index];
    this.storeList.splice(0, index);
    this.storeList.forEach((store) => {
      store.distance = this.getDistanceFromLatLang(this.lat, this.lng, store.location.lat, store.location.lng);
      store.name = `${store.name} (${store.distance} miles)`;
      if (store.storeNumber === 1) {
        firstStore = store;
      }
    });
    this.storeList.sort((a, b) => (Number(a.distance) > Number(b.distance)) ? 1 : -1);
    this.storeList.splice(0, 0, firstStore);
  }

  async isCustomerFleetExist(customerNum, fleetId?) {
    const customerFleet = await this.fleetinfoservice.existsCustomerNumber(customerNum).toPromise();
    if (customerFleet.length > 0) {
      const existingFleet = customerFleet.find(x => x.id === this.fleetId);
      if (this.fleetId && existingFleet) {
        if (existingFleet.location) {
          const existingFleetLocations = existingFleet.location;
          const existingFleetCustomer = existingFleetLocations.find(x => x.customerNumber === customerNum);

          if (existingFleetCustomer && existingFleetCustomer.customerNumber !== customerNum) {
            return true;
          }
        }
        if (existingFleet && existingFleet.id !== this.fleetId) {
          return true;
        }
      } else {
        this.toastService.add({
          severity: 'error', summary: 'Error', detail:
            `Location with customer number #${customerNum} already added to fleet ${customerFleet[0].fleetName}`,
        });
        return true;
      }
    }
    return false;
  }

  // Function to add/update location
  async submit() {
    try {
      this.submitted = true;
      if (this.locationForm.invalid) {
        return;
      }
      const model = ({
        ...this.locationForm.value,
      });
      const customerNum = this.locationForm.value.customerNumber;
      if (typeof this.editableIndex === 'number') {
        model.customerLookup = this.accountCustomer === undefined ? model.customerLookup : this.accountCustomer;
        if (this.accountCustomer) {
          model.customerNumber = this.accountCustomer.customerNumber;
        }

        const index = this.locationList.findIndex(x => x.customerNumber === customerNum);
        if (index >= 0 && index !== this.editableIndex) {
          this.toastService.add({
            severity: 'error', summary: 'Error', detail:
              `Location with customer number #${customerNum} already added to this fleet`,
          });
          this.close();
          return; // already exist
        }
        const isExist = await this.isCustomerFleetExist(customerNum, model.id);
        if (isExist) {
          this.close();
          return;
        }
        const updatemsg = LOCATION_TOAST_MESSAGES.update.addSuccess;
        this.updated.emit({ model, index: this.editableIndex });
      } else {
        const isExist = await this.isCustomerFleetExist(customerNum);
        if (isExist) {
          this.close();
          return;
        }
        model.customerLookup = this.accountCustomer;
        model.customerNumber = this.accountCustomer.customerNumber;
        this.created.emit(model);
        const addmsg = LOCATION_TOAST_MESSAGES.add.addSuccess;
      }

      this.close();
    } catch (e) {
      console.error(e);
    }
  }

  close() {
    this.displayModal = false;
    this.submitted = false;
    this.formMode = FormMode.Add;
    this.editableIndex = null;
    this.locationForm.reset();
    this.customerPicker.clearCustomer();
    this.locationForm.get('poContactName').patchValue('');
    this.locationForm.get('poContactPhone').patchValue('');
    this.locationForm.get('contactName').patchValue('');
    this.locationForm.get('contactPhone').patchValue('');
  }

  searchState() {
    this.state = usStates.map(x => x.name);
  }

  filterStateSearch(event): void {
    const searchText = event.query.toLowerCase();
    this.stateSuggestion = this.state.filter(position => position.toLowerCase().includes(searchText));
  }

  // redirect to add fleet form
  redirectToFleetForm() {
    if (this.fleetId) {
      this.route.navigate([`/addfleet/${this.fleetId}`]);
      return;
    }
    this.route.navigate(['/addfleet']);
  }

}
