import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FleetInfo } from './fleet-info.interface';
import { CrudBaseService } from 'src/app/shared/services/crud-base.service';

@Injectable({
  providedIn: 'root',
})
export class FleetInfoService extends CrudBaseService<FleetInfo>  {
  protected urlPath: string = 'fleet-details';
  constructor(
    protected http: HttpClient,
  ) {
    super();
  }

  // TODO: Some of these routes need to be deleted
  // CrudBaseService handles list, get, update, delete
  // Function to create fleet
  createFleet(model): Observable<any> {
    for (let i = 0; i < model.location.length; i++) {
      model.location[i].customerLookup = JSON.stringify(model.location[i].customerLookup);
    }
    return this.http.post<any>(`${this.url}addfleet`, model);
  }

  // Function to get list of fleet
  getFleetlist(params): Promise<FleetInfo[]> {
    return this.http.get<FleetInfo[]>(`${this.url}list`, { params: this.getHttpParams(params) }).pipe(
      map((res: any) => res.fleet),
    ).toPromise();
  }

  // Function to get available fleet
  getAvailableFleets(): Promise<FleetInfo[]> {
    return this.http.get<FleetInfo[]>(`${this.url}availableFleetList`).toPromise();
  }

  // Function to get available fleet for vehicles
  getAvailableFleetsForVehicles(): Promise<FleetInfo[]> {
    return this.http.get<FleetInfo[]>(`${this.url}availableFleetListForVehicle`).toPromise();
  }

  // Function to get user fleet
  getUserFleet(userId) {
    return this.http.get<FleetInfo>(`${this.url}getUserFleet/${userId}`).toPromise();
  }

  // Function to get keyclosk user associated fleet
  assignedFleetLocationToVehicle(fleetId, vehicleId, model): Promise<FleetInfo> {
    return this.http.post<FleetInfo>(`${this.url}assign/fleet/${fleetId}/vehicle/${vehicleId}`, model).toPromise();
  }

  // Function to get keyclosk user associated fleet
  getKeyCloakUserFleet(userId): Promise<FleetInfo> {
    return this.http.get<FleetInfo>(`${this.url}getUserKeyCloakFleetLocations/${userId}`).toPromise();
  }

  // Function to get particular fleet using Id
  getFleetUsingId(id) {
    return this.http.get(`${this.url}fleet/${id}`);
  }

  // Function to update fleet
  updateFleet(id, model) {
    for (let i = 0; i < model.location.length; i++) {
      model.location[i].customerLookup = JSON.stringify(model.location[i].customerLookup);
    }
    return this.http.put<any>(`${this.url}update/${id}`, model);
  }

  // Function to delete a fleet
  deleteFleet(id: any) {
    return this.http.delete<any>(`${this.url}delete/${id}`);
  }

  // Function to get list of US states in json fomat
  unassignVehicleLocations(vehicleId) {
    return this.http.post<any>(`${this.url}unassign/${vehicleId}`, {});
  }

  existsCustomerNumber(custNumber: any) {
    return this.http.post<any>(`${this.url}existsCustNum/${custNumber}`, {});
  }

  // Function to check is fleet already assigned to any user or vehicle
  isFleetAlreadyAssigned(fleetId: any) {
    return this.http.post<any>(`${this.url}fleetAlreadyAssigned/`, { id: fleetId });
  }

  // Function to check is location already assigned to any user or vehicle
  isLocationAlreadyAssigned(locationId: any) {
    return this.http.post<any>(`${this.url}locationAlreadyAssigned/`, { id: locationId });
  }

}
