import { BrowserModule } from '@angular/platform-browser';
import { NgModule, DoBootstrap, ApplicationRef } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { KeycloakAngularModule, KeycloakService, KeycloakOptions } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { SharedModule } from './shared/shared.module';
import { FleetInfoModule } from './settings/fleet-management/fleet-info.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DriverModule } from './settings/driver-management/driver.module';

const keycloakService = new KeycloakService();

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    KeycloakAngularModule,
    FleetInfoModule,
    DriverModule,
  ],
  providers: [
    { provide: KeycloakService, useValue: keycloakService },
    { provide: LocationStrategy, useClass: HashLocationStrategy }],
  entryComponents: [AppComponent],
})
export class AppModule implements DoBootstrap {
  async ngDoBootstrap(app) {
    try {
      // await keycloakService.init({ config: environment.keycloak, enableBearerInterceptor: true, loadUserProfileAtStartUp: true });
      await keycloakService.init({
        config: environment.keycloak,
        enableBearerInterceptor: true,
        initOptions: {
          checkLoginIframe: false,
        },
      });
      app.bootstrap(AppComponent);
    } catch (error) {
      console.error('Keycloak init failed', error);
    }
  }
}
