<p-toast position="top-left" key="tl"></p-toast>
<form class="service-call-form labelColor" [formGroup]="userForm" (keydown.enter)="$event.preventDefault()">
  <p-panel styleClass="panel">
    <ng-template pTemplate="header" class="card-header error" style="color: #de3937;"><b>Add User Details </b>&nbsp;</ng-template><br>
    <div class="p-grid">
      <div class="p-col-12 p-md-4">
        <app-super-input maxlength="60"
          class="form-control ng-untouched ng-pristine ng-valid p-component"
          label="Email Address" errorMsg="Enter valid email address." [formSubmitted]="isFormSubmitted"
          icon="fa fa-user" [fc]="userForm.controls['userName']">
        </app-super-input>
      </div>
      <div class="p-col-12 p-md-4 setChange">
        <div>
          <app-super-input
            class="form-control ng-untouched ng-pristine ng-valid p-component"
            label="Password" maxlength="40" type="password" [formSubmitted]="isFormSubmitted" icon="fa fa-unlock-alt"
            name="new-password" [fc]="userForm.controls['password']">
          </app-super-input>
        </div>

        <button pButton *ngIf="!isLoading && userForm.controls.id.value !='' && !isChangePassword" type="button"
          class="p-button-secondary pull-right" (click)="changePassword()" label="Change"></button>
        <button pButton pRipple *ngIf="!isLoading && userForm.controls.id.value !='' && isChangePassword" type="button"
          label="Cancel" (click)="cancelChangePassword()" class="btn1 btn-danger"></button>

      </div>
      <div class="p-col-12 p-md-4">
        <app-super-input
          [ngClass]="{'confirm-password':isFormSubmitted && userForm.controls['confirmPassword'].value != userForm.controls['password'].value}"
          class="form-control  ng-untouched ng-pristine ng-valid p-component"
          label="Confirm password" maxlength="40" type="password" [formSubmitted]="isFormSubmitted"
          icon="fa fa-unlock-alt" [fc]="userForm.controls['confirmPassword']">
        </app-super-input>
        <label
          *ngIf="isFormSubmitted && userForm.controls['confirmPassword'].value != '' && userForm.controls['confirmPassword'].value != userForm.controls['password'].value"
          class="invalid-text"> **Confirm Password does not match</label>
      </div>
    </div>
    <br>
    <div class="p-grid">
      <div class="p-col-12 p-md-4">
        <app-super-input maxlength="60"
          class="form-control ng-untouched ng-pristine ng-valid p-component"
          label="Firstname" [formSubmitted]="isFormSubmitted" icon="fa fa-address-book-o"
          [fc]="userForm.controls['firstName']">
        </app-super-input>
      </div>
      <div class="p-col-12 p-md-4">
        <app-super-input class="form-control ng-untouched ng-pristine ng-valid p-component"
          label="Lastname" maxlength="40" [formSubmitted]="isFormSubmitted" icon="fa fa-address-book-o"
          [fc]="userForm.controls['lastName']"></app-super-input>
      </div>
      <div class="p-col-12 p-md-4">
        <app-super-input class="form-control ng-untouched ng-pristine ng-valid p-component"
          label="Phone number" maxlength="40" [formSubmitted]="isFormSubmitted" icon="fa fa-phone-square"
          [fc]="userForm.controls['phone']"></app-super-input>
      </div>
    </div>
    <br>
    <div class="p-grid">
      <div [ngStyle]="{'display':(isDriverAttached?'none': '')}" class="p-col-12 p-md-4">
        <label>
          <p-radioButton name="roles" [disabled]="isDriverAttached || (this.userId && this.userId!= '0')" value="customer-driver" formControlName="roles"></p-radioButton>
          Driver
        </label>
        &nbsp; &nbsp;
        <label>
          <p-radioButton name="roles" [disabled]="isDriverAttached || (this.userId && this.userId!= '0')" value="customer-employee" formControlName="roles"></p-radioButton>
          Fleet Employee
        </label>
        
      </div>
      <div [ngClass]="{'p-md-8': !isDriverAttached, 'p-md-12': isDriverAttached}" class="p-col-12">
        <app-super-input class="form-control ng-untouched ng-pristine ng-valid p-component"
          label="Notes" maxlength="500" icon="" [fc]="userForm.controls['notes']"></app-super-input>
      </div>
    </div>
    <br>

    <div>
      <p-table #dtFleet [value]="fleetDetails"
        [globalFilterFields]="['fleetName','fleetAddress','fleetCity', 'fleetState','addressCity','fleetPrimaryName', 'fleetPrimaryPhone']"
        [paginator]="true" [rows]="5" [scrollable]="true" scrollHeight="200px">
        <ng-template pTemplate="caption">
          <div>

            <label type="button" class="text-left pull-left mt-1"> Fleet List
            </label>
            <label class="invalid-text text-left pull-left mt-1"
              *ngIf="this.selectedLocations == 0 && this.isFormSubmitted"> <b>&nbsp;
                **Please select at least one fleet location.</b></label>
            <input pInputText type="text" class="text-right pull-right"
              (input)="dtFleet.filterGlobal($event.target.value, 'contains')" placeholder="Search" /><br><br>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th class="text-left">Fleet Name</th>
            <th class="text-left">Address</th>
            <th class="text-left">City</th>
            <th class="text-left">State</th>
            <th class="text-left">Primary Name</th>
            <th class="text-left">Primary Phone</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-fleet>
          <tr>
            <td>
              <input *ngIf="fleet.isChecked" (click)="fleetSelectCheckBox(fleet._id, fleet.fleetName)"
                (change)="fleetSelectCheckBox(fleet._id, fleet.fleetName)" type="radio" name="fleet" checked>
              <input *ngIf="!fleet.isChecked" (click)="fleetSelectCheckBox(fleet._id, fleet.fleetName)" type="radio"
                name="fleet">
              {{fleet.fleetName}}
            </td>
            <td>{{fleet.fleetAddress}}</td>
            <td>{{fleet.fleetCity}}</td>
            <td>{{fleet.fleetState}}</td>
            <td>{{fleet.fleetPrimaryName}}</td>
            <td>{{fleet.fleetPrimaryPhone | mask: '(000) 000-0000' }}</td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" let-fleet>
          <tr>
            <td colspan="6" class="no-record-msg">No fleet added yet..!!</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <br>

    <div class="p-grid btn-width">
      <div class="p-col-12 p-md-4">
        <label *ngIf="selectedFleetName && selectedFleetName!= ''">Selected Fleet : <b>{{selectedFleetName}}</b>
          <button pButton type="button" (click)="fleetSelectCheckBox()" class="text-center form-control"
            label="Edit Location">
          </button>
        </label>
      </div>
      <div class="p-col-12 p-md-4">
        <div class="text-center">
          <button pButton type="button" *ngIf="!isLoading" (click)="redirectToList()" class="text-center"
            class="text-center" label="Cancel">
          </button> &nbsp; &nbsp;

          <button *ngIf="!isDriverAttached && !isLoading && userForm.controls.id.value == ''" pButton type="button"
            class="text-center" (click)="saveUserInfo(false)" class="submitBtn text-center" label="Save User"
            icon="fa fa-user">
          </button>

          <button *ngIf="isDriverAttached && !isLoading && userForm.controls.id.value == ''" pButton type="button"
            class="text-center" (click)="saveUserInfo(false)" class="add-driver-user text-center"
            label="Add Driver User" icon="fa fa-user">
          </button>

          <button *ngIf="!isLoading && userForm.controls.id.value != ''" pButton type="button"
            (click)="saveUserInfo(true)" class="submitBtn text-center" label="Update User" icon="fa fa-user">
          </button>&nbsp; &nbsp;
          <button *ngIf="isLoading" type="button" class="buttonload">
            <i class="fa fa-refresh fa-spin"></i>
          </button>
        </div>
      </div>
      <div class="p-col-12 p-md-4">

      </div>
    </div>


  </p-panel>
</form>


<p-dialog header="Fleet locations" [(visible)]="display" [modal]="true" [style]="{width: '80vw'}" [baseZIndex]="10000"
  [draggable]="true" [resizable]="false">
  <div>
    <p-table #dt [value]="fleetLocations" [scrollable]="true" scrollHeight="250px"
      [globalFilterFields]="['locationInfo','contactPhone','contactName', 'addressState','addressCity','address']"
      [paginator]="true" [rows]="20">

      <ng-template pTemplate="caption">
        <div>
          <label>
            <p-checkbox (onChange)="selectAllFleetLocations($event)" class="text-left pull-left mt-1" type="checkbox" checked></p-checkbox>
            Select All
          </label>
          <input pInputText type="text" class="text-right pull-right"
            (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search" /><br><br>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th class="text-left">
            Location Name
          </th>
          <th class="text-left">
            Contact Name
          </th>
          <th class="text-left">Contact Phone</th>
          <th class="text-left">State</th>
          <th class="text-left">City</th>
          <th class="text-left">
            Address
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-location>
        <tr>
          <td>
            <input type="checkbox" *ngIf="location.isChecked" id="myCheck"
              (click)="selectLocation($event, location._id)" checked>
            <input type="checkbox" *ngIf="!location.isChecked" (click)="selectLocation($event, location._id)"
              id="myCheck">
            {{location.locationInfo}}
          </td>
          <td>{{location.contactName}}</td>
          <td>{{location.contactPhone  | mask: '(000) 000-0000' }}</td>
          <td>{{location.addressState}}</td>
          <td>{{location.addressCity}}</td>
          <td>{{location.address}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-location>
        <tr>
          <td colspan="6" class="no-record-msg">No records found..!!</td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-grid">
      <div class="p-col-12 p-md-10">
      </div>
      <div class="p-col-12 p-md-2">
        <button pButton type="button" class="p-button-secondary pull-right" (click)="hideDialog()"
          label="Close"></button>
      </div>
    </div>
  </div>
</p-dialog>