<p-panel styleClass="panel">
  <p-table #dt [value]="driverList" [paginator]="false"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [globalFilterFields]="['firstName','lastName','phone','unit', 'notes']">
    <ng-template pTemplate="caption">
      <div>
        <label type="button" class="text-left pull-left mt-1"> List Driver</label>
        <a class="mr-1" [routerLink]="['/driver-info/add']">
          <button pButton type="button" class="submitBtn text-left pull-right" label="Add Driver" icon="fa fa-id-card">
          </button> </a>
        <a class="pull-right"> &nbsp; &nbsp;</a>
        <input pInputText type="text" class="pull-right" (input)="dt.filterGlobal($event.target.value, 'contains')"
          placeholder="Search" />
      </div>
      <br />
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th class="text-left">
          Driver Name
        </th>
        <th class="text-left">
          Fleet Name
        </th>
        <th class="text-left">
          Phone
        </th>
        <th class="text-left">
          Unit
        </th>
        <th class="text-left">
          Notes
        </th>
        <th class="text-left">
          Action
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-driver>
      <tr>
        <td>{{driver.firstName}} {{driver.lastName}}</td>
        <td>{{driver.fleet.fleetName}}</td>
        <td>{{driver.phone | mask: '(000) 000-0000'}}</td>
        <td>{{driver.unit}}</td>
        <td>{{driver.notes}}</td>
        <td>
          <a [routerLink]="['/driver-info/add/'+driver._id]"><button pButton type="button" icon="fa fa-pencil-square-o"
              class="p-button-text"></button></a> &nbsp; &nbsp;
          <button pButton type="button" (click)="showLocationModal(driver)" icon="fa fa-eye"
            class="p-button-text"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-driver>
      <tr>
        <td colspan="6" class="no-record-msg">No records found..!!</td>
      </tr>
    </ng-template>
  </p-table>
</p-panel>

<p-dialog [(visible)]="display" [modal]="true" [style]="{width: '80vw'}" [baseZIndex]="10000" [draggable]="false"
  [resizable]="false">
  <div>
    <p-table #dtLocation [value]="selectedData"
      [globalFilterFields]="['locationInfo','address','addressCity', 'addressState', 'contactName', 'contactPhone']"
      [paginator]="false" [scrollable]="true" scrollHeight="200px">

      <ng-template pTemplate="caption">
        <label type="button" class="text-left pull-left mt-1"> Associated Fleet Locations
        </label>

        <input pInputText type="text" class="text-right pull-right"
          (input)="dtLocation.filterGlobal($event.target.value, 'contains')" placeholder="Search" />
        <br /><br>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th class="text-left">
            Location Info
          </th>
          <th class="text-left">
            Address
          </th>
          <th class="text-left">
            City
          </th>
          <th class="text-left">
            State
          </th>
          <th class="text-left">
            Contact Name
          </th>
          <th class="text-left">
            Contact Phone
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-data>
        <tr>
          <td>{{data.locationInfo}}</td>
          <td>{{data.address}}</td>
          <td>{{data.addressCity}}</td>
          <td>{{data.addressState}}</td>
          <td>{{data.contactName}}</td>
          <td>{{data.contactPhone | mask: '(000) 000-0000'}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-data>
        <tr>
          <th colspan="6" class="no-record-msg">
            No records found..!!
          </th>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-grid">
      <div class="p-col-12 p-md-10">

      </div>
      <div class="p-col-12 p-md-2">
        <button pButton type="button" class="p-button-secondary pull-right" (click)="closeLocationModal()"
          label="Close"></button>
      </div>
    </div>
  </div>
</p-dialog>