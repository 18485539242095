import { Injectable } from '@angular/core';
import { SocketService } from '../shared/services/socket.service';
import { Observable } from 'rxjs';

export interface Notification {
  title?: string;
  body: string;
  data?: any;
  icon?: string;
  path?: string;
  date: Date;
  clicked: boolean;
  storeNumber?: number;
}

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  protected socketNamespaceName = 'notifications';
  private socketEvent = 'notification';
  private socket: SocketService;

  constructor() {
    this.socket = new SocketService(this.socketNamespaceName);
  }

  listen(): Observable<Notification> {
    return this.socket.onEvent(this.socketEvent);
  }
}
