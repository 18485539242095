export enum FormMode {
  Add = 'Submit',
  Edit = 'Update',
  Undefined = '',
}

export const usStates = [
  {
    name: 'Alabama',
    code: 'AL',
  },
  {
    name: 'Alaska',
    code: 'AK',
  },
  {
    name: 'Arizona',
    code: 'AZ',
  },
  {
    name: 'Arkansas',
    code: 'AR',
  },
  {
    name: 'California',
    code: 'CA',
  },
  {
    name: 'Colorado',
    code: 'CO',
  },
  {
    name: 'Connecticut',
    code: 'CT',
  },
  {
    name: 'Delaware',
    code: 'DE',
  },
  {
    name: 'District Of Columbia',
    code: 'DC',
  },
  {
    name: 'Florida',
    code: 'FL',
  },
  {
    name: 'Georgia',
    code: 'GA',
  },
  {
    name: 'Hawaii',
    code: 'HI',
  },
  {
    name: 'Idaho',
    code: 'ID',
  },
  {
    name: 'Illinois',
    code: 'IL',
  },
  {
    name: 'Indiana',
    code: 'IN',
  },
  {
    name: 'Iowa',
    code: 'IA',
  },
  {
    name: 'Kansas',
    code: 'KS',
  },
  {
    name: 'Kentucky',
    code: 'KY',
  },
  {
    name: 'Louisiana',
    code: 'LA',
  },
  {
    name: 'Maine',
    code: 'ME',
  },
  {
    name: 'Maryland',
    code: 'MD',
  },
  {
    name: 'Massachusetts',
    code: 'MA',
  },
  {
    name: 'Michigan',
    code: 'MI',
  },
  {
    name: 'Minnesota',
    code: 'MN',
  },
  {
    name: 'Mississippi',
    code: 'MS',
  },
  {
    name: 'Missouri',
    code: 'MO',
  },
  {
    name: 'Montana',
    code: 'MT',
  },
  {
    name: 'Nebraska',
    code: 'NE',
  },
  {
    name: 'Nevada',
    code: 'NV',
  },
  {
    name: 'New Hampshire',
    code: 'NH',
  },
  {
    name: 'New Jersey',
    code: 'NJ',
  },
  {
    name: 'New Mexico',
    code: 'NM',
  },
  {
    name: 'New York',
    code: 'NY',
  },
  {
    name: 'North Carolina',
    code: 'NC',
  },
  {
    name: 'North Dakota',
    code: 'ND',
  },
  {
    name: 'Ohio',
    code: 'OH',
  },
  {
    name: 'Oklahoma',
    code: 'OK',
  },
  {
    name: 'Oregon',
    code: 'OR',
  },
  {
    name: 'Pennsylvania',
    code: 'PA',
  },
  {
    name: 'Rhode Island',
    code: 'RI',
  },
  {
    name: 'South Carolina',
    code: 'SC',
  },
  {
    name: 'South Dakota',
    code: 'SD',
  },
  {
    name: 'Tennessee',
    code: 'TN',
  },
  {
    name: 'Texas',
    code: 'TX',
  },
  {
    name: 'Utah',
    code: 'UT',
  },
  {
    name: 'Vermont',
    code: 'VT',
  },
  {
    name: 'Virginia',
    code: 'VA',
  },
  {
    name: 'Washington',
    code: 'WA',
  },
  {
    name: 'West Virginia',
    code: 'WV',
  },
  {
    name: 'Wisconsin',
    code: 'WI',
  },
  {
    name: 'Wyoming',
    code: 'WY',
  },
];

export const chartBGColors = [
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#FF0000',
  '#0000FF',
  '#FFFF00',
  '#00FFFF',
  '#FF00FF',
  '#C0C0C0',
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFFF00',
  '#00FFFF',
  '#FF00FF',
  '#C0C0C0',
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFFF00',
  '#00FFFF',
  '#FF00FF',
  '#C0C0C0',
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#000000',
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFFF00',
  '#00FFFF',
  '#FF00FF',
  '#C0C0C0',
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFFF00',
  '#00FFFF',
  '#FF00FF',
  '#C0C0C0',
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
];

export const appRoles = {
  stmEmployee: 'stm-employee',
  customerEmployee: 'customer-employee',
  customerDriver: 'customer-driver',
};

export const addressLocationTypes = [
  { label: 'Breakdown', value: 'breakdown', icon: 'fas fa-road' },
  { label: 'At Yard', value: 'yard', icon: 'fas fa-garage-car' },
];

export const vehicleTypes = [
  { label: 'Truck', value: 'truck', icon: 'fas fa-truck-moving' },
  { label: 'Trailer', value: 'trailer', icon: 'fas fa-truck-loading' },
];

export const wheelOKTypes = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const mapIcons = [
  { id: 'store', url: '../../../assets/img/stm_store_32px.png' },
  { id: 'breakdowns', url: '../../../assets/img/dispatchMap/problem.png' },
];

export const wheelPositions = [
  'RS - Right Steer',
  'LS - Left Steer',
  'LFO - Left Front Outer',
  'LFI - Left Front Inner',
  'LRO - Left Rear Outer',
  'LRI - Left Rear Inner',
  'RFO - Right Front Outer',
  'RFI - Right Front Inner',
  'RRO - Right Rear Outer',
  'RRI - Right Rear Inner',
];
