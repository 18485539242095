<p-toast position="top-left"></p-toast>
<form class="service-call-form labelColor" [formGroup]="driverForm" (keydown.enter)="$event.preventDefault()">
  <p-panel styleClass="panel">
    <ng-template pTemplate="header" class="card-header error" style="color: #de3937;"><b>Add Driver Details </b>&nbsp;</ng-template><br>
    <div class="p-grid">

      <div class="p-col-12 p-md-4">
        <app-super-input maxlength="60"
          class="form-control ng-untouched ng-pristine ng-valid p-component"
          label="First Name" errorMsg="Enter driver first name." [formSubmitted]="isFormSubmitted" icon="fa fa-user"
          [fc]="driverForm.controls['firstName']">
        </app-super-input>
      </div>

      <div class="p-col-12 p-md-4">
        <app-super-input maxlength="60"
          class="form-control ng-untouched ng-pristine ng-valid p-component"
          label="Last Name" errorMsg="Enter driver last name." [formSubmitted]="isFormSubmitted" icon="fa fa-user"
          [fc]="driverForm.controls['lastName']">
        </app-super-input>
      </div>

      <div class="p-col-12 p-md-4">
        <app-super-input class="form-control ng-untouched ng-pristine ng-valid p-component"
          label="Phone" maxlength="20" [formSubmitted]="isFormSubmitted" icon="fa fa-unlock-alt" name="phone"
          [fc]="driverForm.controls['phone']">
        </app-super-input>
      </div>

      <div class="p-col-12 p-md-4" [ngClass]="{'mt__19':(this.driverForm.controls.unit.value)}">
        <label *ngIf="this.driverForm.controls.unit.value" class="label">Unit</label>

        <p-autoComplete maxlength="30" [formControl]="driverForm.controls['unit']" placeholder="Unit"
          [suggestions]="unitSuggestions" field="description" (onSelect)="unitSelectEvent($event)"
          (completeMethod)="unitSearch($event)"
          [ngClass]="{'invalid-auto-complete': isFormSubmitted && (!driverForm.controls['unit'].valid)}">
        </p-autoComplete>

        <label *ngIf="this.driverForm.controls.unit.disabled" class="invalid-text">You need to select fleet location
          first.</label>

      </div>

      <div class="p-col-12 p-md-8">
        <app-super-input class="form-control ng-untouched ng-pristine ng-valid p-component"
          label="Notes" maxlength="500" [formSubmitted]="isFormSubmitted" icon="fa fa-unlock-alt"
          [fc]="driverForm.controls['notes']">
        </app-super-input>
      </div>

    </div>

    <br>

    <div>
      <p-table #dtFleet [value]="fleetDetails"
        [globalFilterFields]="['fleetName','fleetAddress','fleetCity', 'fleetState','addressCity','fleetPrimaryName', 'fleetPrimaryPhone']"
        [paginator]="true" [rows]="5" [scrollable]="true" scrollHeight="200px">
        <ng-template pTemplate="caption">
          <div>

            <label type="button" class="text-left pull-left mt-1"> Fleet
              List
            </label>

            <label class="invalid-text text-left pull-left mt-1"
              *ngIf="this.selectedLocations == 0 && this.isFormSubmitted"> <b>&nbsp; **Please select at
                least
                one fleet
                location.</b></label>

            <input pInputText type="text" class="text-right pull-right"
              (input)="dtFleet.filterGlobal($event.target.value, 'contains')" placeholder="Search" /><br><br>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th class="text-left">Fleet Name</th>
            <th class="text-left">Address</th>
            <th class="text-left">City</th>
            <th class="text-left">State</th>
            <th class="text-left">Primary Name</th>
            <th class="text-left">Primary Phone</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-fleet>
          <tr>
            <td>
              <input *ngIf="fleet.isChecked" (click)="fleetSelectCheckBox(fleet._id, fleet.fleetName)"
                (change)="fleetSelectCheckBox(fleet._id, fleet.fleetName)" type="radio" name="fleet"
                [disabled]="isDriverAttached==true" checked>
              <input *ngIf="!fleet.isChecked" (click)="fleetSelectCheckBox(fleet._id, fleet.fleetName)" type="radio"
                name="fleet" [disabled]="isDriverAttached==true">
              {{fleet.fleetName}}
            </td>
            <td>{{fleet.fleetAddress}}</td>
            <td>{{fleet.fleetCity}}</td>
            <td>{{fleet.fleetState}}</td>
            <td>{{fleet.fleetPrimaryName}}</td>
            <td>{{fleet.fleetPrimaryPhone | mask: '(000) 000-0000' }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-location>
          <tr>
            <td colspan="6" class="no-record-msg">No records found..!!</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <br>

    <div class="p-grid btn-width">
      <div [ngClass]="{'p-md-4':(selectedFleetName && selectedFleetName!= '')}" class="p-col-12">
        <label *ngIf="selectedFleetName && selectedFleetName!= ''">Selected Fleet : <b>{{selectedFleetName}}</b>
          <button pButton type="button" [disabled]="isDriverAttached" (click)="fleetSelectCheckBox()"
            class="text-center form-control" label="Edit Locations">
          </button>
        </label>
      </div>
      <div class="p-col-12"
        [ngClass]="{'p-md-6':(selectedFleetName && selectedFleetName!= ''), 'p-md-12':!(selectedFleetName && selectedFleetName!= '')}">
        <div class="text-center">
          <button (click)="redirectToList()" pButton type="button" class="text-center" (click)="redirectToList()"
            class="cancelBtn text-center" label="Cancel">
          </button>
          <button *ngIf="!isLoading && driverForm.controls.id.value == ''" pButton type="button" class="text-center"
            (click)="saveDriverInfo(false)" class="userBtn text-center" label="Save Driver" icon="fa fa-user">
          </button>
          <button *ngIf="!isDriverAttached && !isLoading && driverForm.controls.id.value == ''" pButton type="button"
            class="text-center" (click)="redirectToUser(false)" class="addBtn text-center" label="Create User"
            icon="fa fa-user">
          </button>
          <button *ngIf="!isLoading && driverForm.controls.id.value != ''" pButton type="button"
            (click)="saveDriverInfo(true)" class="userBtn  text-center" label="Update Driver" icon="fa fa-user">
          </button>&nbsp; &nbsp;
          <button *ngIf="isLoading" type="button" class="buttonload">
            <i class="fa fa-refresh fa-spin"></i>
          </button>
        </div>
      </div>
      <div class="p-col-12 p-md-2">

      </div>
    </div>


  </p-panel>
</form>

<!-- Locations modal popup -->
<p-dialog header="Fleet locations" [(visible)]="display" [modal]="true" [style]="{width: '80vw'}" [baseZIndex]="10000"
  [draggable]="true" [resizable]="false">
  <div>
    <p-table #dt [value]="fleetLocations" [scrollable]="true" scrollHeight="250px"
      [globalFilterFields]="['locationInfo','contactPhone','contactName', 'addressState','addressCity','address']"
      [paginator]="true" [rows]="20">

      <ng-template pTemplate="caption">
        <div>
          <label>
            <p-checkbox (onChange)="selectAllFleetLocations($event)" class="text-left pull-left mt-1" type="checkbox" checked></p-checkbox>
            Select All
          </label>
          
          <input pInputText type="text" class="text-right pull-right"
            (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search" /><br><br>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th class="text-left">
            Location Name
          </th>
          <th class="text-left">
            Contact Name
          </th>
          <th class="text-left">Contact Phone</th>
          <th class="text-left">State</th>
          <th class="text-left">City</th>
          <th class="text-left">
            Address
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-location>
        <tr>
          <td>
            <input type="checkbox" *ngIf="location.isChecked" id="myCheck"
              (click)="selectLocation($event, location._id)" checked>
            <input type="checkbox" *ngIf="!location.isChecked" (click)="selectLocation($event, location._id)"
              id="myCheck">
            {{location.locationInfo}}
          </td>
          <td>{{location.contactName}}</td>
          <td>{{location.contactPhone  | mask: '(000) 000-0000' }}</td>
          <td>{{location.addressState}}</td>
          <td>{{location.addressCity}}</td>
          <td>{{location.address}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-location>
        <tr>
          <td colspan="6" class="no-record-msg">No records found..!!</td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-grid">
      <div class="p-col-12 p-md-10">
      </div>
      <div class="p-col-12 p-md-2">
        <button pButton type="button" class="p-button-secondary pull-right" (click)="hideDialog()"
          label="Close"></button>
      </div>
    </div>
  </div>
</p-dialog>