import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from 'src/app/layout/layout/layout.component';
import { FleetInfoComponent } from './fleet-info/fleet-info.component';
import { FleetInfoItemsComponent } from './fleet-info-items/fleet-info-items.component';
import { FleetGuardGuard } from './fleet-guard.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [FleetGuardGuard],
    component: LayoutComponent,
    children: [
      { path: '', component: FleetInfoItemsComponent, data: { breadcrumb: 'Fleet Info' } },
      { path: 'addfleet/:id', component: FleetInfoComponent, data: { breadcrumb: 'Fleet Info' } },
      { path: 'addfleet', component: FleetInfoComponent, data: { breadcrumb: 'Fleet Info' } },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FleetInfoRoutingModule { }
