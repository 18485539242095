import { keycloakDev } from './keycloak.env';

// const BACKEND_BASE_URL = 'https://api.roadside.dev.stmtires.com';
const BACKEND_BASE_URL = 'https://api.roadside.dev.stmtires.com';
export const environment = {
  production: false,
  keycloak: keycloakDev,
  backendBaseURL: BACKEND_BASE_URL,
  apiURL: `${BACKEND_BASE_URL}/api/v1`,
  mapboxKey: 'pk.eyJ1IjoiZGNpLWRldmVsb3BtZW50IiwiYSI6ImNrOHJ1anp0OTA0aWgzbXBnMDdzeWxiNHcifQ.8BFnA2bcgG9I_PBAuwShew',
};
