import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormControl, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-super-input',
  templateUrl: './super-input.component.html',
  styleUrls: ['./super-input.component.scss'],
})
export class SuperInputComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() icon: string;
  @Input() required: boolean;
  @Input() mask: string;
  @Input() fc: FormControl;
  @Input() errorMsg: string;
  @Input() formSubmitted: boolean;
  @Input() maxlength: number;
  @Input() type?: string;
  @Input() isNumberOnly?: boolean;
  @Input() isReadonly?: boolean;
  @Input() isSpecialCharNotAllowd?: boolean;
  @Input() tabIndex: number;
  @Input() autofocus: boolean;

  isRequired: boolean;
  constructor() { }

  ngOnInit() {
    this.checkValidators();
  }

  ngOnChanges(): void {
    this.checkValidators();
  }

  // Checks validators to see what elements/styles to apply
  checkValidators(): void {
    this.hasRequiredValidator();
    this.hasPhoneValidator();
  }

  // Checks to see if form control has required validator and sets required css.
  hasRequiredValidator(): void {
    // If required was set on input ignore validator
    if (this.required !== undefined) {
      this.isRequired = this.required;
      if (!this.errorMsg) {
        this.errorMsg = `${this.label} is required.`;
      }
      return;
    }

    if (this.fc && this.fc.validator) {
      const validator = this.fc.validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
        if (!this.errorMsg) {
          this.errorMsg = `${this.label} is required.`;
        }
        return;
      }
    }

    this.isRequired = false;
  }

  hasPhoneValidator(): void {
    // If mask is defined ignore checking for validator
    if (this.mask !== undefined) {
      return;
    }

    if (this.fc && this.fc.validator) {
      const validator = this.fc.validator({} as AbstractControl);
      if (validator && validator.invalidPhoneNumber) {
        this.mask = '(000) 000-0000';
        if (!this.errorMsg) {
          this.errorMsg = 'Please enter a valid phone number.';
        }
        return;
      }
    }
  }

  onKeyDown(event) {
    if (this.isNumberOnly) {
      if (event.key >= 0 || event.key === 'Backspace' || event.key === 'Tab') {
        return true;
      }
      return false;
    }
  }

  onKeyPress(e) {
    if (this.isSpecialCharNotAllowd) {
      const valid = (e.which === 32 || e.which === 44 || e.which === 45) || (e.which >= 48 && e.which <= 57) ||
        (e.which >= 65 && e.which <= 90) || (e.which >= 97 && e.which <= 122);
      if (!valid) {
        e.preventDefault();
      }
    }
  }
}
