<div *ngIf="fc" class="p-inputgroup">
  <span class="p-inputgroup-addon" [ngClass]="{'invalid': !fc.valid && (fc.touched || formSubmitted)}"><i [ngClass]="icon"></i></span>
  <span class="p-float-label">
    <input
      appRequiredCornerTriangle
      (keydown)="onKeyDown($event)"
      (keypress)="onKeyPress($event)"
      type={{type}}
      pInputText
      [maxlength]="maxlength"
      [formControl]="fc"
      [mask]="mask"
      [required]="isRequired"
      [invalid]="!fc.valid && fc.status != 'DISABLED' && (fc.touched || formSubmitted)"
      [tabindex]="tabIndex"
      [autofocus]="autofocus"
    >

    <label for="float-input"
      [ngClass]="{'invalid-text': !fc.valid && fc.status != 'DISABLED' && (fc.touched || formSubmitted)}">{{label}}</label>
    <!-- <div *ngIf="isRequired" [ngClass]="{'invalid-asterisk': !fc.valid && fc.status != 'DISABLED' && (fc.touched || formSubmitted)}"
      class="ui corner label"><i class="fas fa-asterisk icon"></i></div> -->
  </span>
</div>
<label *ngIf="(fc.touched || formSubmitted) && (!fc.valid && fc.status != 'DISABLED')"
  class="invalid-text">** {{errorMsg ? errorMsg : 'This field needs attention.'}}</label>