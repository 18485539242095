import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Customer } from '../customers.interface';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CustomersService } from '../customers.service';
import { LazyLoadEvent } from 'primeng/api';
import { PagedResponse } from 'src/app/shared/services/crud-base.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-customer-advanced-search',
  templateUrl: './customer-advanced-search.component.html',
  styleUrls: ['./customer-advanced-search.component.scss'],
})
export class CustomerAdvancedSearchComponent implements OnInit {
  customers: Customer[];
  pageSize: number = 5;
  searchForm: FormGroup;
  searchQuery: any = {};
  selectedCustomer: Customer;
  searching: boolean = false;
  customersLoading: boolean;
  totalCustomerResults: number;
  searched: boolean;
  cols = [
    { field: 'name', header: 'Name' },
    { field: 'sortName', header: 'Sort Name' },
    { field: 'locationCode', header: 'Location Code', width: '10%' },
    { field: 'customerNumber', header: 'Customer #', width: '10%' },
    { field: 'nationalAccountCustomerNumber', header: 'Vendor Customer Number' },
    { field: 'address1', header: 'Address' },
    { field: 'address3', header: 'City/State' },
  ];
  @Output() onCustomerSelect = new EventEmitter();
  @ViewChild('result') resultDiv: ElementRef;

  constructor(private fb: FormBuilder, private customersService: CustomersService) {}

  ngOnInit() {
    this.searchForm = this.fb.group({
      customerNumber: [''],
      nationalAccountCustomerNumber: [''],
      excludeVendorCustomers: [''],
      nationalAccountVendorNumber: [''],
      address3: [''],
      locationCode: [''],
      name: [''],
      sortName: [''],
    });
  }

  public reset() {
    this.searchForm.reset();
    this.customers = null;
    this.searchForm.patchValue({ excludeVendorCustomers: 'N' });
  }

  async customerSearch(): Promise<void> {
    this.searching = true;
    this.searchQuery = this.buildSearchQuery(this.searchForm.value);
    this.searched = true;
    await this.getCustomers(this.searchQuery);
    this.searching = false;
    this.resultDiv.nativeElement.scrollIntoView();
  }

  buildSearchQuery(formValues: any): any {
    const query = {};
    for (const key in formValues) {
      if (formValues[key]) {
        switch (key) {
          case 'sortName':
            query['sortName__beginsWith'] = formValues[key];
            break;
          case 'excludeVendorCustomers':
            if (String(formValues[key]).toUpperCase() === 'Y') query['nationalAccountCustomerNumber__isNull'] = true;
            break;
          default:
            const searchKey = `${key}__contains`;
            query[searchKey] = formValues[key];
            break;
        }
      }
    }

    // sorting
    query['sort'] = ['sortName', 'customerNumber'];
    return query;
  }

  async paginate(event: LazyLoadEvent) {
    this.customersLoading = true;
    this.searchQuery.pageSize = event.rows;
    this.searchQuery.pageNumber = event.first / event.rows + 1;

    await this.getCustomers(this.searchQuery);
    this.customersLoading = false;
  }

  getCustomers(query: any): Promise<Customer[]> {
    query.pageSize = this.pageSize;
    query.isDeleted = false;

    return this.customersService
      .findMany(query)
      .pipe(
        map((res: PagedResponse<Customer>) => {
          this.totalCustomerResults = res.totalCount;
          this.customers = res.documents;
          return res.documents;
        }),
      )
      .toPromise();
  }

  onRowSelect(event) {
    this.selectedCustomer = event.data;
    this.onCustomerSelect.emit(event.data);
  }

  onKeyUp(event) {
    switch (event.key) {
      case 'Enter':
        this.customerSearch();
        break;
    }
  }
}
