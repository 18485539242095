<p-menubar [model]="menuItems" styleClass="topbar">
  <ng-template pTemplate="start">
    <img src="assets/img/logo/STMLogo_HOR.svg" height="55" [routerLink]="['/']" />
  </ng-template>
  <!-- <img src="assets/img/logo/STMLogo_VER.svg" [routerLink]="['/']" class="p-menubar-logo mobile-only" />

  <div class="icon-badges">
    <span *ngFor="let badge of menuBadges" class="fa-layers fa-fw fa-2x">
      <i [ngClass]="badge.icon"></i>
      <span class="fa-layers-counter" style="background:Tomato; font-size: 1.5em;">14</span>
    </span>
    <span *ngFor="let badge of menuBadges" class="fa-layers fa-fw fa-2x">
      <i class="fas fa-clipboard-list-check"></i>
      <span class="fa-layers-counter fa-2x" style="background:Tomato; font-size: 1.5em;">14</span>
    </span>
  </div> -->

  <ng-template pTemplate="end">
    <span class="topbar-right-menu-item notifications">
      <a (click)="toggleNotificationsMenu()">
        <i class="topbar-icon fa fa-fw fa-bell-o"></i>
        <span *ngIf="unreadNotificationCount > 0"
          class="topbar-badge animated rubberBand">{{unreadNotificationCount}}</span>
      </a>
  
      <div *ngIf="showNotificationsMenu" class="notification-container">
        <h3>Notifications <i class="fa fa-bell-o"></i></h3>
  
        <label *ngFor="let notification of notifications; let idx = index;" (click)="navigate(notification)"
          class="notification-row notification new p-grid p-nogutter" [ngClass]="{'new': !notification.clicked}">
          <div class="p-col-1 icon center">
            <i class="fa-2x icon" [ngClass]="notification.icon"></i>
          </div>
          <div class="p-col-10 message">
            <span class="title">{{notification.title}}</span>
            <span class="description">{{notification.body}}</span>
          </div>
          <div class="p-col-1 center" style="justify-content: center;" (click)="removeNotification(idx)">
            <i class="fas fa-times fa-2x remove-btn" title="Remove Notification"></i>
          </div>
          <div class="p-col-12 time">{{notification.date | timeago}}</div>
        </label>
      </div>
    </span>
  
    <span *ngIf="isShownSettingsMenu" class="topbar-right-menu-item settings-menu">
      <p-menu #navigation [popup]="true" [model]="settingsMenu.menuItems"></p-menu>
      <a (click)="navigation.toggle($event)">
        <i class="topbar-icon fa fa-cog fa-2x"></i>
      </a>
    </span>
      
    <span class="topbar-right-menu-item user-menu">
      <p-menu #menu [popup]="true" [model]="userMenu.menuItems"></p-menu>
      <a (click)="menu.toggle($event)">
        <span *ngIf="userMenu.displayName">
          <i class="topbar-icon fas fa-user-circle fa-2x"></i>
          <span class="user-menu-name">{{userMenu.displayName}} </span>
          <i class="topbar-icon far fa-angle-down"></i>
        </span>
      </a>
    </span>
  </ng-template>
</p-menubar>