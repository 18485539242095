import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QueryParams } from '../shared/services/base-http.service';
import { Observable } from 'rxjs';
import { Store } from './stores.interface';
import { CrudBaseService, PagedResponse } from '../shared/services/crud-base.service';
import { take, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class StoreService extends CrudBaseService<Store> {
  protected urlPath: string = 'stores';

  constructor(protected http: HttpClient) {
    super();
  }

  findMany(params: QueryParams = {}): Observable<PagedResponse<Store>> {
    const queryParams = { ...params };

    // Set default store params
    queryParams.pageSize = params.pageSize || 'all';
    queryParams.isActive = params.isActive || true;
    queryParams.storeType__in = params.storeType__in || ['RETAIL', 'OTHER'];
    queryParams.select = params.select || ['-geocodeResponse', '-__meta'];

    // We will filter out all OTHER type stores except 1 (call center)
    const allowedOtherTypeStoreNumbers: number[] = [1];

    if (!environment.production) {
      // We use store 99 and 900 for testing
      queryParams.storeType__in = params.storeType__in || ['RETAIL', 'CORPORATE', 'OTHER'];
      allowedOtherTypeStoreNumbers.push(900);
    }

    return super.findMany(queryParams)
      .pipe(map((storeResponse: PagedResponse<Store>) => {
        storeResponse.documents =
          storeResponse.documents.filter((s) => {
            return !s.storeType || s.storeType !== 'OTHER' || allowedOtherTypeStoreNumbers.includes(s.storeNumber);
          });
        return storeResponse;
      }));
  }

  getStoreByNumber(storeNumber: string | number): Promise<Store> {
    return this.findMany({ storeNumber }).pipe(
      take(1),
      map((res: PagedResponse<Store>) => res.documents[0]),
    ).toPromise();
  }

}
