import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { LayoutService } from '../layout/layout.service';
import { Dialog } from '../layout/layout.interface';
import { AuthUser } from './auth.interface';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private userBehaviorSubject: BehaviorSubject<AuthUser> = new BehaviorSubject<AuthUser>(null);
  user: Observable<AuthUser> = this.userBehaviorSubject.asObservable();
  userStores: string[];
  keycloakTokenInvalid = false;

  constructor(private keycloakService: KeycloakService, private layoutService: LayoutService) {
    this.getUser();
  }

  private async getUser(): Promise<void> {
    let user: AuthUser = { } as AuthUser;
    if (await this.keycloakService.isLoggedIn()) {
      user = await this.keycloakService.loadUserProfile() as AuthUser;

      // https://github.com/mauriciovigolo/keycloak-angular/issues/30
      user.id = this.keycloakService.getKeycloakInstance().subject;
    }
    this.userBehaviorSubject.next(user);
    if (user.attributes && user.attributes.store_numbers) {
      this.userStores = user.attributes.store_numbers;
    }
  }

  showMissingStoresMessage(): void {
    const dialog: Dialog = {
      visible: true,
      header: 'Missing Information',
      buttonText: 'Reload',
      buttonIcon: 'fas fa-sync-alt',
      body: 'Your account does not have any stores assigned to it. Please contact support.',
      func: () => {
        this.keycloakService.login();
      },
    };
    this.layoutService.updateDialog(dialog);
  }

  showUnauthorisedErrorMessages(): void {
    const dialog: Dialog = {
      visible: true,
      header: 'Unauthorized Access',
      buttonText: 'logout',
      buttonIcon: 'fa fa-sign-out',
      body: 'You are not authorized to access this page.',
      func: () => {
        this.keycloakService.logout();
      },
    };
    this.layoutService.updateDialog(dialog);
  }
}
