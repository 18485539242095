import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/layout/toast.service';
import { Customer } from '../../../customers/customers.interface';
import { FormMode, usStates } from '../../../core/constants';
import { ServiceCall } from '../../../service-calls/service-call.interface';
import { phoneNumberValidator } from '../../../shared/validators/phone-validator';
import { FleetInfoService } from '../fleet-info.service';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { UserService } from 'src/app/settings/user-management/user.service';
import { MessageService } from 'primeng/api';

const FLEET_TOAST_MESSAGES = {
  add: {
    addSuccess: {
      summary: 'Created',
      detail: 'Fleet Info Created!',
    },
    addError: {
      summary: 'Error',
      detail: 'A problem has occurred. Please make sure all of the required boxes have been filled out.',
    },
  },
  update: {
    addSuccess: {
      summary: 'Created',
      detail: 'Fleet Info Updated!',
    },
  },
};

@Component({
  selector: 'app-fleet-info',
  templateUrl: './fleet-info.component.html',
  styleUrls: ['./fleet-info.component.scss'],
})
export class FleetInfoComponent implements OnInit, AfterViewInit {
  fleetForm: FormGroup;
  submitted: boolean = false;
  isSaving: boolean;
  formMode = FormMode.Add;
  // displayModal: boolean;
  locationList = [];
  deletePop = false;
  display = false;
  isAddlocation: boolean = true;
  theLength: number = 0;

  deleteIdLocation: any;
  locationNotDelete: boolean = false;

  first = 0;
  rows = 10;

  stateSuggestion = [];
  state = [];
  fleetLocations = [];
  locationIds = [];

  private serviceCallValue: ServiceCall;
  @Input() get serviceCall() { return this.serviceCallValue; }
  @Input() selectedCustomer: Customer;

  formId: string;

  constructor(
    private fb: FormBuilder,
    private toastService: ToastService,
    private fleetinfoservice: FleetInfoService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    protected userService: UserService,
  ) {

  }

  ngOnInit() {
    this.searchState();
    this.isSaving = false;
    this.creatFleetForm(null);
    const fleetPage = this.activatedRoute.snapshot.paramMap.get('id');
    if (fleetPage) {
      this.formMode = FormMode.Edit;
      return this.fleetinfoservice.getFleetUsingId(fleetPage).subscribe((res: any) => {
        const formValue = res;
        this.formId = res.id;
        this.creatFleetForm(formValue);
      });
    }
  }

  searchState() {
    this.state = usStates.map(x => x.name);
  }

  filterStateSearch(event): void {
    const searchText = event.query.toLowerCase();
    this.stateSuggestion = this.state.filter(position => position.toLowerCase().includes(searchText));
  }

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  ngAfterViewInit() {
    if (this.formId) {
      this.fleetForm.get('fleetNotes').valueChanges.subscribe((res) => {
        this.display = true;
        this.theLength = res.length;
      });
    }
  }

  // Function to create controller of fleet form
  creatFleetForm(formValue) {
    if (!formValue) {
      this.fleetForm = this.fb.group({
        id: [''],
        fleetName: ['', Validators.required],
        fleetAddress: ['', Validators.required],
        fleetCity: ['', Validators.required],
        fleetState: ['', Validators.required],
        fleetPrimaryName: '',
        fleetPrimaryPhone: ['', phoneNumberValidator],
        fleetNotes: ['', Validators.maxLength(500)],
        fleetPOName: '',
        fleetPOPhone: ['', phoneNumberValidator],
        location: new FormArray([]),
        zip: ['', Validators.required],
      });
    } else {
      this.fleetForm = this.fb.group({
        id: [formValue.id],
        fleetName: [formValue.fleetName, Validators.required],
        fleetAddress: [formValue.fleetAddress, Validators.required],
        fleetCity: [formValue.fleetCity, Validators.required],
        fleetState: [formValue.fleetState, Validators.required],
        fleetPrimaryName: [formValue.fleetPrimaryName],
        fleetPrimaryPhone: [formValue.fleetPrimaryPhone == null ? '' : formValue.fleetPrimaryPhone, [phoneNumberValidator]],
        fleetNotes: [formValue.fleetNotes],
        fleetPOName: [formValue.fleetPOName],
        fleetPOPhone: [formValue.fleetPOPhone == null ? '' : formValue.fleetPOPhone, phoneNumberValidator],
        location: [formValue.location],
        selected: [formValue.selected],
        zip: [formValue.zip, Validators.required],
      });
      this.locationList = [...formValue.location];
    }

  }

  // Function to check PO button content
  checkedPOButton(val1: string, val2: number, isChecked: boolean) {
    if (isChecked) {
      this.fleetForm.get('fleetPOName').patchValue(val1);
      this.fleetForm.get('fleetPOPhone').patchValue(val2);
    } else {
      this.fleetForm.get('fleetPOName').patchValue(' ');
      this.fleetForm.get('fleetPOPhone').patchValue(' ');
    }
  }

  // Function to add/update fleet information
  async addUpdateFleetInfo(): Promise<void> {
    this.submitted = true;
    const model = this.fleetForm.value;
    if (this.fleetForm.invalid) {
      return;
    }
    if (model.location.length === 0) {
      this.isAddlocation = false;
      return;
    }
    if (this.formId) {
      model.location = this.locationList;
      try {
        const updateFleet = FLEET_TOAST_MESSAGES.update.addSuccess;
        await this.fleetinfoservice.updateFleet(this.formId, model).pipe(take(1)).toPromise();
        this.submitted = false;
        // this.toastService.add({ severity: 'success', summary: updateFleet.summary, detail: updateFleet.detail });
        this.messageService.add({ severity: 'success', summary: updateFleet.summary, detail: updateFleet.detail });
        this.route.navigate(['/fleet-info']);
      } catch (error) {
        const updateFleet = FLEET_TOAST_MESSAGES.add.addError;
        const msg = updateFleet.detail;
        this.toastService.add({ severity: 'error', summary: updateFleet.summary, detail: msg });
      }
      return;
    }
    try {
      model.location = this.locationList;
      const addFleet = FLEET_TOAST_MESSAGES.add.addSuccess;
      await this.fleetinfoservice.createFleet(model).pipe(take(1)).toPromise();
      this.submitted = false;
      this.toastService.add({ severity: 'success', summary: addFleet.summary, detail: addFleet.detail });
      this.route.navigate(['/fleet-info']);
    } catch (error) {
      console.error(error);
      const addFleet = FLEET_TOAST_MESSAGES.add.addError;
      const msg = addFleet.detail;
      this.toastService.add({ severity: 'error', summary: addFleet.summary, detail: msg });
    }
  }

  // Function to update locations
  onUpdateLocations(updateEvent) {
    this.fleetForm.controls.location.value[updateEvent.index] = updateEvent.model;
    this.locationList = this.fleetForm.controls.location.value;
    this.fleetForm.markAsDirty();
  }

  // Function to create loctions
  onCreateLocations(model) {
    this.fleetForm.controls.location.value.push(model);
    this.locationList = [...this.fleetForm.controls.location.value];
    this.fleetForm.markAsDirty();
  }

  // Function to display delete popup
  showDeletePOPUP(loc, id) {

    this.fleetinfoservice.isLocationAlreadyAssigned(loc._id).subscribe((result) => {
      if (result.data) {
        this.toastService.add({
          severity: 'error',
          summary: 'Already Assigned', detail: result.msg,
        });
      } else {
        if (loc.userId && loc.selected === true) {
          this.locationNotDelete = true;
          return;
        }
        this.deleteIdLocation = id;
        this.deletePop = true;
      }
    });
  }

  // Function to delete popup location
  deletePopLocation() {
    this.fleetForm.controls.location.value.splice(this.deleteIdLocation, 1);
    this.locationList = this.fleetForm.controls.location.value;
    this.fleetForm.markAsDirty();
    this.deletePop = false;
  }

  // Function to reset the form
  reset() {
    this.fleetForm.reset();
  }

  // redirect to fleet list
  redirectToList() {
    this.route.navigate(['/fleet-info']);
  }

}
