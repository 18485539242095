import { Component, OnInit, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from '../layout.service';
import { Layout, UserMenu, MenuBadge } from '../layout.interface';
import { NotificationsService, Notification } from '../notifications.service';
import { Router } from '@angular/router';
import { ToastService } from '../toast.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  userProfile: any;
  userRoles: string[];

  menuItems: MenuItem[] = [];
  settingsMenu: UserMenu = {};
  userMenu: UserMenu = {};
  notifications: Notification[] = [];
  menuBadges: MenuBadge[] = [];
  unreadNotificationCount: number = 0;
  showNotificationsMenu: boolean = false;
  isShownSettingsMenu: boolean = false;

  constructor(
    layoutService: LayoutService,
    private router: Router,
    private notificationService: NotificationsService,
    private toastService: ToastService,
    protected keycloakAngular: KeycloakService,
  ) {

    layoutService.layout.subscribe((layout: Layout) => {
      this.menuItems = (layout.topbar && layout.topbar.menuItems) ? layout.topbar.menuItems : this.menuItems;

      this.settingsMenu = layout.topbar && layout.topbar.settingsMenu ? layout.topbar.settingsMenu : this.settingsMenu;

      this.userMenu = layout.topbar && layout.topbar.userMenu ? layout.topbar.userMenu : this.userMenu;

      const index = this.settingsMenu && this.settingsMenu.menuItems && this.settingsMenu.menuItems.length ?
        this.settingsMenu.menuItems[0].items.findIndex(x => x.visible) :
        -1;
      if (index >= 0) {
        this.isShownSettingsMenu = true;
      }
    });

  }

  ngOnInit(): void {
    this.listenForNotifications();
  }

  async getUserProfile() {
    if (await this.keycloakAngular.isLoggedIn()) {
      this.userProfile = await this.keycloakAngular.loadUserProfile();
    }
  }

  async getUserRoles() {
    if (await this.keycloakAngular.isLoggedIn()) {
      this.userRoles = await this.keycloakAngular.getUserRoles();
    }
  }

  async listenForNotifications(): Promise<void> {
    await this.getUserProfile();

    this.notificationService.listen().subscribe((notification: Notification) => {
      // only display global and store specific notifications
      if (!notification.storeNumber || this.userProfile?.attributes?.store_numbers?.includes(String(notification.storeNumber))) {
        this.unreadNotificationCount++;
        this.notifications.unshift(notification);
        this.toastService.add({ severity: 'success', summary: notification.title, detail: notification.body });
        const audio = new Audio('../../../assets/sounds/notification.mp3');
        audio.play();
      }
    });
  }

  toggleNotificationsMenu(): void {
    this.showNotificationsMenu = !this.showNotificationsMenu;
    this.unreadNotificationCount = 0;
  }

  navigate(notification: Notification): void {
    this.router.navigate([notification.path]);
    this.showNotificationsMenu = false;
    this.unreadNotificationCount = 0;
    notification.clicked = true;
  }

  onNotificationsHide(event: any): void {
    this.unreadNotificationCount = 0;
  }

  removeNotification(index: number): void {
    this.notifications.splice(index, 1);
  }
}
