import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FleetInfo, LocationInfo } from 'src/app/settings/fleet-management/fleet-info.interface';
import { FleetInfoService } from 'src/app/settings/fleet-management/fleet-info.service';
import { PagedResponse } from 'src/app/shared/services/crud-base.service';
import { VehicleInfo } from 'src/app/settings/vehicle-management/model/Vehicle';
import { VehicleService } from 'src/app/settings/vehicle-management/vehicle.service';
import { DriverInfo } from '../model/DriverInfo';
import { MessageService } from 'primeng/api';
import { DriverService } from '../driver.service';
import { phoneNumberValidator } from 'src/app/shared/validators/phone-validator';
import { USER_TOAST_MESSAGES } from '../../user-management/users.component';

@Component({
  selector: 'app-driver-info',
  templateUrl: './driver-info.component.html',
  styleUrls: ['./driver-info.component.scss'],
})
export class DriverInfoComponent implements OnInit {

  driverForm: FormGroup;
  isFormSubmitted: boolean;
  selectedLocations: number;
  driverId: string;
  selectedFleetId: string;
  selectedFleetName: string;
  fleetDetails: FleetInfo[] = [];
  fleetLocations: LocationInfo[] = [];
  isLoading: boolean;
  unitSuggestions: any = [];
  listUnits: any = [];
  display: boolean = false;
  private driverValue: DriverInfo;
  isDriverAttached: boolean = false;

  @Input() get driver() { return this.driverValue; }
  constructor(
    private formBuilder: FormBuilder,
    private vehicleService: VehicleService,
    private driverService: DriverService,
    private messageService: MessageService,
    private fleetServie: FleetInfoService,
    private router: ActivatedRoute,
    private route: Router,
  ) { }

  ngOnInit() {
    this.isFormSubmitted = false;
    this.driverId = this.router.snapshot.params.id;
    this.createDriverForm();
    this.bindFleetLocations();
    this.bindUnits();

    if (this.driverId) {
      setTimeout(() => {
        this.bindDriverDetails(this.driverId);
      });
    }
  }

  // bind driver details using driver id
  // Author - Prashant S4
  bindDriverDetails(driverId) {
    this.driverService.getDriverById(driverId).subscribe((driverInfo: any) => {
      if (driverInfo.isDriverAttached) {
        this.isDriverAttached = true;
      }
      this.createDriverForm(driverInfo);
      const fleetId: string = driverInfo.fleet._id ? driverInfo.fleet._id : driverInfo.fleet;
      const selectedIndex = this.fleetDetails.findIndex(x => (x._id === fleetId));
      if (selectedIndex >= 0) {
        this.fleetDetails[selectedIndex].isChecked = true;
        this.selectedFleetId = this.fleetDetails[selectedIndex]._id;
        this.selectedFleetName = this.fleetDetails[selectedIndex].fleetName.toString();
        this.fleetLocations = this.fleetDetails[selectedIndex].location;
        driverInfo.fleetLocations.forEach((loc: any) => {
          if (loc) {
            const index = this.fleetLocations.findIndex(x => x._id === loc.locationId);
            if (index >= 0) {
              this.fleetLocations[index].isChecked = true;
            }
          }
        });
      }
    });
  }

  // bind available fleet and their locations
  // Created By - Prashant S4
  async bindFleetLocations() {
    const fleetList: FleetInfo[] = await this.fleetServie.getAvailableFleets();
    this.fleetDetails = fleetList.reverse();
  }

  // Form creation for driver
  // Created By - Prashant S4
  createDriverForm(driverData?: DriverInfo) {
    if (driverData) {
      this.driverForm = this.formBuilder.group({
        id: [driverData._id],
        fleetId: driverData.fleet ? driverData.fleet._id : '',
        fleetName: driverData.fleet ? driverData.fleet.fleetName : '',
        firstName: [driverData.firstName, [Validators.required]],
        lastName: [driverData.lastName, [Validators.required]],
        phone: [driverData.phone, [Validators.required, phoneNumberValidator]],
        notes: [driverData.notes],
        unit: [{ description: driverData.unit }],
      });
    } else {
      this.driverForm = this.formBuilder.group({
        id: [''],
        fleetId: '',
        fleetName: '',
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        phone: ['', [Validators.required, phoneNumberValidator]],
        notes: [''],
        unit: [''],
      });
      this.driverForm.controls.unit.disable();
    }

    if (this.isDriverAttached) {
      this.driverForm.controls.firstName.disable();
      this.driverForm.controls.lastName.disable();
      this.driverForm.controls.phone.disable();
      this.driverForm.controls.notes.disable();
      setTimeout(() => {
        this.driverForm.controls.unit.enable();
      });
    }

  }

  // bind list of units/vehicles
  // Created By - Prashant S4
  bindUnits() {
    const query = {
      pageSize: 'all',
      vehicleType: 'truck',
    };
    this.vehicleService.findMany(query).subscribe((res: PagedResponse<VehicleInfo>) => {
      this.listUnits = res.documents.map((s) => {
        return {
          _id: s._id,
          description: `${s.units} - ${s.description}`,
          units: s.units,
          vehicleType: s.vehicleType,
          tireSize: s.tireSize,
          driveTirePreference: s.driveTirePreference,
          steerTirePreference: s.steerTirePreference,
          fleetName: s.fleetName,
          fleetId: s.fleetId,
          fleetLocations: s.fleetLocations,
          notes: s.notes,
          fleet: s.fleet,
          associatedTrailer: s.associatedTrailer,
        };
      });
    });
  }

  // get suggestion for unit auto complete fields.
  // Created By - Prashant S4
  unitSearch(event: any): void {
    const searchText = event.query.toLowerCase();
    this.unitSuggestions = this.listUnits.filter(x =>
      (x.fleet === this.selectedFleetId &&
        (x.description.toLowerCase().includes(searchText) || x.units.toLowerCase().includes(searchText))));
  }

  unitSelectEvent(event: any): void {
    this.driverForm.controls['unit'].setValue({ description: event.units });
  }

  // fleet selection event.unitSearch
  // Created By - Prashant S4
  async fleetSelectCheckBox(id?, fleetName?) {
    if (id && id !== this.selectedFleetId) {
      this.selectedFleetId = id;
      this.selectedFleetName = fleetName;
      this.fleetLocations = [];
      this.fleetDetails.forEach((fleet) => {
        if (fleet._id === id) {
          fleet.isChecked = true;
          this.fleetLocations = fleet.location;
        }
        else { fleet.isChecked = false; }
      });
    }
    this.showDialog();
  }

  // show fleet locations dialog
  // Created By - Prashant S4
  showDialog() {
    this.display = true;
  }

  // hide fleet locations dialog
  // Created By - Prashant S4
  hideDialog() {
    this.display = false;
    this.selectedLocations = 0;
    this.fleetLocations.forEach((location) => {
      if (location.isChecked) {
        this.selectedLocations++;
      }
    });
    if (this.selectedLocations > 0) {
      this.driverForm.controls.unit.enable();
    } else {
      this.driverForm.controls.unit.disable();
    }
  }

  // select all fleet locations
  // Created By - Prashant S4
  selectAllFleetLocations(event) {
    this.fleetLocations.forEach((element) => {
      if (event) {
        element.isChecked = true;
      } else {
        element.isChecked = false;
      }
    });
  }

  // select single fleet location
  // Created By - Prashant S4
  async selectLocation(event, id) {
    this.fleetLocations.forEach((element) => {
      if (element._id === id) {
        element.isChecked = event.target.checked;
      }
    });
  }

  // save update driver details.
  // Created By - Prashant S4
  async saveDriverInfo(isUpdate) {
    try {
      this.isFormSubmitted = true;

      this.driverForm.controls.fleetId.setValue(this.selectedFleetId);
      this.driverForm.controls.fleetName.setValue(this.selectedFleetName);
      const driverData = { ...this.driverForm.value };

      driverData.fleetLocations = [];
      this.selectedLocations = 0;
      this.fleetLocations.forEach((location) => {
        if (location.isChecked) {
          this.selectedLocations++;
          driverData.fleetLocations.push({ locationId: location._id });
        }
      });

      driverData.unit = driverData.unit.description ? driverData.unit.description : driverData.unit;

      if (this.driverForm.invalid || this.selectedLocations === 0) {
        return;
      }
      this.isLoading = true;

      let data;
      driverData.fleet = this.selectedFleetId;
      if (!isUpdate) {
        data = await this.driverService
          .create(driverData).toPromise();
      } else {
        driverData._id = this.driverId;
        data = await this.driverService
          .updateDriver(driverData).toPromise();
      }
      this.isLoading = false;
      if (!isUpdate) {
        const addUser = USER_TOAST_MESSAGES.add.addSuccess;
        this.messageService.add({ severity: 'success', summary: addUser.summary, detail: addUser.detail });
      } else {
        const updateUser = USER_TOAST_MESSAGES.update.addSuccess;
        this.messageService.add({ severity: 'success', summary: updateUser.summary, detail: updateUser.detail });
      }
      this.redirectToList();
    } catch (e) {
      this.isLoading = false;
    }
  }

  // redirect to driver list
  // Created By - Prashant S4
  redirectToList() {
    this.route.navigate(['/list']);
  }

  // redirect to manage user
  // Created By - Prashant S4
  redirectToUser() {
    this.selectedLocations = 0;
    this.driverForm.controls.fleetId.setValue(this.selectedFleetId);
    this.driverForm.controls.fleetName.setValue(this.selectedFleetName);
    const driverData = { ...this.driverForm.value };

    driverData.fleetLocations = [];
    this.fleetLocations.forEach((location) => {
      if (location.isChecked) {
        this.selectedLocations++;
        driverData.fleetLocations.push({ locationId: location._id });
      }
    });
    driverData.unit = (driverData.unit && driverData.unit.description) ? driverData.unit.description : driverData.unit;
    driverData.fleet = this.selectedFleetId;
    this.driverService.setAttachedUserData(driverData);
    this.driverService.setIsAttachedUser(true);
    this.route.navigate(['/users/add'], { queryParams: { attachUser: 'true' } });
  }

}
