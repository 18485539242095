import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudBaseService } from '../../shared/services/crud-base.service';
import { UserInfo } from './model/users';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService extends CrudBaseService<UserInfo>{
  protected urlPath: string = 'users';
  constructor(protected http: HttpClient) {
    super();
  }

  // TODO: Some of these routes need to be deleted
  // CrudBaseService handles list, get, update, delete
  // Service to get keycloak user
  getKeyCloakUser(keyCloakUserId: any): Promise<UserInfo> {
    return this.http.get<UserInfo>(`${this.url}keyCloakUser/${keyCloakUserId}`).toPromise();
  }

  // Service to get user by id
  getFleetUser(id): Promise<UserInfo> {
    return this.http.get<UserInfo>(`${this.url}listUser/${id}`).toPromise();
  }

  // Service to get list of users
  getFleetUsers() {
    return this.http.get(`${this.url}/listUser`);
  }

  // // Service to get fleet locations
  // getFleetLocations() {
  //   return this.http.get(`${this.url}/fleets`);
  // }

  // Service to add fleet user
  addFleetUser(model) {
    for (let index = 0; index < model.fleetLocations.length; index++) {
      if (model.fleetLocations._id) {
        delete model.fleetLocations._id;
      }
    }
    return this.http.post(`${this.url}create`, model);
  }

  // Service to update fleet user
  updateFleetUser(userId, model) {
    return this.http.put(`${this.url}update/${userId}`, model);
  }

  // Service to assign role to user
  assignRoleToUser(userId) {
    return this.http.post(`${environment.keycloak.url}/realms/${environment.keycloak.realm}/clients/ ${userId}/roles`, {});
  }

}
