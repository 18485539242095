import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
  transform(value: number | string): string {
    const val = value.toString();

    if (val.length === 10) {
      return `(${val.substring(0, 3)}) ${val.substr(3, 3)}-${val.substr(6)}`;
    }

    if (val.length === 7) {
      return `${val.substr(0, 3)}-${val.substr(3)}`;
    }

    return val;
  }
}
