import { AfterViewInit, ContentChildren, Directive, ElementRef, Injector, Input, OnChanges, OnDestroy, OnInit, QueryList } from '@angular/core';
import { AutoComplete } from 'primeng/autocomplete';
import { Dropdown } from 'primeng/dropdown';

type ElementType = 'p-autocomplete' | 'p-dropdown' | 'input';
const ALLOWED_ELEMENTS: ElementType[] = ['p-autocomplete', 'p-dropdown', 'input'];
@Directive({
  selector: '[appRequiredCornerTriangle]',
})
export class RequiredCornerTriangleDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input() required: boolean;
  @Input() invalid: boolean;
  viewInitialized = false;
  elementType: ElementType;
  id: string;
  autocomplete?: AutoComplete;
  dropdown?: Dropdown;
  observers: MutationObserver[] = [];
  constructor(
    private el: ElementRef,
    injector: Injector,
  ) {
    this.elementType = el.nativeElement.tagName.toLowerCase();
    if (!ALLOWED_ELEMENTS.includes(this.elementType)) {
      throw new Error(`Required corner triangle directive can only be applied to ${ALLOWED_ELEMENTS.join(', ')}. Element type "${this.elementType}" is not supported`);
    }
    this.id = `required-corner-triangle-${Math.floor(Math.random() * 10) + 1}`;

    switch (this.elementType) {
      case 'p-autocomplete':
        this.autocomplete = injector.get<AutoComplete>(AutoComplete);
        break;
      case 'p-dropdown':
        this.dropdown = injector.get<Dropdown>(Dropdown);
        break;
      case 'input':
        break;
    }
  }

  ngOnDestroy() {
    for (const observer of this.observers) {
      observer.disconnect();
    }
  }

  ngAfterViewInit() {
    this.addCornerTriangle();
    switch (this.elementType) {
      case 'p-autocomplete':
        const observer = new MutationObserver(() => {
          const loader = this.autocomplete.el.nativeElement.querySelector('.p-autocomplete-loader');
          if (loader) {
            loader.style.right = '1.25em';
          }
        });
        observer.observe(this.autocomplete.el.nativeElement.querySelector('.p-autocomplete'), { childList: true });
        this.observers.push(observer);
        break;
    }
    this.viewInitialized = true;
  }

  ngOnChanges() {
    if (this.viewInitialized) {
      this.addCornerTriangle();
    }
  }

  private get anchor() {
    switch (this.elementType) {
      case 'p-autocomplete':
        return this.autocomplete.inputEL.nativeElement.parentElement;
      case 'p-dropdown':
        return this.dropdown.el.nativeElement.children[0];
      case 'input':
        return this.el.nativeElement.parentElement;
        break;
    }
  }

  private shiftElements() {
    let element: any;
    switch (this.elementType) {
      case 'p-dropdown':
        element = this.dropdown.el.nativeElement.querySelector('.p-dropdown-trigger');
        if (element) {
          element.style.right = '1.25em';
        }
        break;
    }
  }

  private unshiftElements() {
    let element: any;
    switch (this.elementType) {
      case 'p-autocomplete':
        element = this.autocomplete.el.nativeElement.querySelector('.p-autocomplete-loader');
        if (element) {
          element.style.right = 'initial';
        }
        break;
      case 'p-dropdown':
        element = this.dropdown.el.nativeElement.querySelector('.p-dropdown-trigger');
        if (element) {
          element.style.right = 'initial';
        }
        break;
      case 'input':
        break;
    }
  }

  private addCornerTriangle() {
    const corner: HTMLDivElement = this.anchor ? this.anchor.querySelector(`#${this.id}`) as HTMLDivElement : null;
    const color = this.invalid ? '#D65454' : '#E8E8E8';
    if (corner) {
      this.anchor.removeChild(corner);
      this.unshiftElements();
    }
    if (this.required) {
      const corner: HTMLDivElement = document.createElement('div');
      corner.id = this.id;
      corner.style.width = '0';
      corner.style.height = '0';
      corner.style.borderTop = `30px solid ${color}`;
      corner.style.borderLeft = '30px solid transparent';
      corner.style.position = 'absolute';
      corner.style.right = '1px';
      corner.style.top = '1px';
      const asterisk: HTMLSpanElement = document.createElement('span');
      asterisk.className = 'fa fa-asterisk';
      asterisk.style.fontSize = '10px';
      asterisk.style.top = '-32px';
      asterisk.style.left = '-13px';
      asterisk.style.position = 'relative';
      corner.appendChild(asterisk);
      this.anchor.style.position = 'relative';
      this.anchor.appendChild(corner);
      this.shiftElements();
    }
  }
}
