import { Component, OnInit, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { phoneNumberValidator } from '../../shared/validators/phone-validator';
import { UserService } from './user.service';
import { User, UserInfo } from './model/users';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { take } from 'rxjs/operators';
import { FleetInfoService } from '../fleet-management/fleet-info.service';
import { FleetInfo, LocationInfo } from '../fleet-management/fleet-info.interface';
import { DriverService } from '../driver-management/driver.service';
import { appRoles } from 'src/app/core/constants';

export const USER_TOAST_MESSAGES = {
  add: {
    addSuccess: {
      summary: 'Success Message',
      detail: 'User created successfully',
    },
    addError: {
      summary: 'Error Message',
      detail: 'Error in adding user',
    },
  },
  update: {
    addSuccess: {
      summary: 'Success Message',
      detail: 'User updated successfully!',
    },
    addError: {
      summary: 'Error Message',
      detail: 'Error in updating user',
    },
  },
};
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {

  userForm: FormGroup;
  isFormSubmitted: boolean;
  fleetDetails: FleetInfo[] = [];
  fleetLocations: LocationInfo[] = [];
  isSelectLocationValidationMsg: boolean;
  selectedLocations: number = 0;
  selectedFleetId: string = '';
  selectedFleetName: string = '';
  display: boolean = false;
  userId: string;
  emailRegexValidation: string = '^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$.';
  isEmailValid: boolean = false;
  isLoading: boolean = false;
  isChangePassword: boolean = true;
  isDriverAttached: boolean = false;

  private userValue: User;
  @Input() get user() { return this.userValue; }

  constructor(
    private formBuilder: FormBuilder,
    private fleetServie: FleetInfoService,
    private route: Router,
    protected userService: UserService,
    private router: ActivatedRoute,
    private messageService: MessageService,
    private driverService: DriverService,
  ) { }

  roles = [{ name: 'Driver', code: 'driver' }, { name: 'Fleet Employee', code: 'fleet_employee' }];

  async ngOnInit() {

    this.isFormSubmitted = false;
    const attachDriver = this.driverService.getIsAttachedUser();
    if (attachDriver) {
      this.isDriverAttached = true;
    }
    this.userId = this.router.snapshot.params.id;
    this.createUserForm();
    await this.bindFleetLocations();

    if (this.userId) {
      this.isChangePassword = false;
      this.getUserById(this.userId);
    } else if (this.isDriverAttached) { // creating any user from driver from.
      const driverInfo = this.driverService.getAttachedUserData();
      if (driverInfo) {
        this.driverService.setAttachedUserData(null);
        this.driverService.setIsAttachedUser(false);
        this.createDriverForm(driverInfo);
      }
    } else {
      this.userId = '0';
    }
  }

  // Form creation for driver user type, navigating from driver.
  // Created By - Prashant S4
  createDriverForm(driverInfo: any) {

    this.userForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,4}$')]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      firstName: driverInfo.firstName,
      lastName: driverInfo.lastName,
      phone: [driverInfo.phone ? driverInfo.phone : '', [Validators.required, phoneNumberValidator]],
      fleetName: [''],
      notes: driverInfo.notes ? driverInfo.notes : '',
      id: '',
      roles: appRoles.customerDriver,
      fleetId: '0',
      unit: driverInfo.unit ? driverInfo.unit : '',
    });
    this.selectedFleetId = driverInfo.fleetId;

    const selectedIndex = this.fleetDetails.findIndex(x => (x._id === driverInfo.fleetId));
    if (selectedIndex >= 0) {
      this.fleetDetails[selectedIndex].isChecked = true;
      this.fleetLocations = this.fleetDetails[selectedIndex].location;
      this.selectedFleetName = this.fleetDetails[selectedIndex].fleetName.toString();
    }
    this.selectedLocations = 0;
    if (selectedIndex >= 0 && driverInfo.fleetLocations.length > 0) {
      driverInfo.fleetLocations.forEach((loc: any) => {
        if (loc) {
          const index = this.fleetLocations.findIndex(x => x._id === loc.locationId);
          if (index >= 0) {
            this.selectedLocations++;
            this.fleetLocations[index].isChecked = true;
          }
        }
      });
    }
  }

  // get user by id
  async getUserById(id) {
    const userDetails = await this.userService.getFleetUser(id);
    if (userDetails) {
      this.selectedFleetId = userDetails.fleetId;
      this.isDriverAttached = userDetails.isDriverAttached;
      this.createUserForm(userDetails);
      const selectedIndex = this.fleetDetails.findIndex(x => (x._id === userDetails.fleetId));
      if (selectedIndex >= 0) {
        this.fleetDetails[selectedIndex].isChecked = true;
        this.selectedFleetId = this.fleetDetails[selectedIndex]._id;
        this.selectedFleetName = this.fleetDetails[selectedIndex].fleetName.toString();
        this.fleetLocations = this.fleetDetails[selectedIndex].location;
        userDetails.fleetLocations.forEach((loc: any) => {
          if (loc) {
            const index = this.fleetLocations.findIndex(x => x._id === loc.locationId);
            if (index >= 0) {
              this.fleetLocations[index].isChecked = true;
            }
          }
        });
      }
    }
  }
  // bind available fleet and their locations
  async bindFleetLocations() {
    const fleetList: FleetInfo[] = await this.fleetServie.getAvailableFleets();
    this.fleetDetails = fleetList.reverse();
  }

  // Form creation for user
  createUserForm(userData?: UserInfo) {
    if (!userData) {
      this.userForm = this.formBuilder.group({
        userName: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,4}$')]],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        phone: ['', [Validators.required, phoneNumberValidator]],
        fleetName: [''],
        notes: '',
        id: '',
        roles: appRoles.customerEmployee,
        fleetId: '0',
        isDriverAttached: false,
      });
    } else {
      this.userForm = this.formBuilder.group({
        userName: [{ value: userData.userName, disabled: true }],
        password: [{ value: '*****', disabled: true }, Validators.required],
        confirmPassword: [{ value: '*****', disabled: true }, Validators.required],
        firstName: [userData.firstName, Validators.required],
        lastName: [userData.lastName, Validators.required],
        phone: [userData.phone, [Validators.required, phoneNumberValidator]],
        fleetName: userData.fleetName,
        notes: userData.notes,
        id: userData._id,
        roles: [{ value: userData.roles, disabled: true }],
        fleetId: userData.fleetId,
        userId: userData.userId,
        isChangePassword: false,
        isDriverAttached: userData.isDriverAttached ? true : false,
      });
    }

  }

  // set user locations
  setLocations(locations: any[]): void {
    for (const location of locations) {
      this.addLocation(location);
    }
  }

  // add user locations
  addLocation(location?: any) {
    const locations: any[] = [];
    let formGroup;
    if (location) {
      formGroup = this.formBuilder.group({
        locationId: [location.locationId],
      });
    }
    this.userForm.controls.location = formGroup;
  }

  // save update user details
  async saveUserInfo(isUpdate) {
    try {
      this.selectedLocations = 0;
      this.isFormSubmitted = true;

      this.userForm.controls.fleetId.setValue(this.selectedFleetId);
      this.userForm.controls.fleetName.setValue(this.selectedFleetName);
      const userdata = { ...this.user, ...this.userForm.value };
      userdata.fleetLocations = [];
      this.fleetLocations.forEach((location) => {
        if (location.isChecked) {
          this.selectedLocations++;
          userdata.fleetLocations.push({ locationId: location._id });
        }
      });
      if (this.userForm.invalid || this.selectedLocations === 0 ||
        (userdata.password && userdata.password.toString() !== userdata.confirmPassword.toString())) {
        return;
      }
      this.isLoading = true;

      userdata.name = `${userdata.firstName} ${userdata.lastName}`;
      let data;
      userdata.isDriverAttached = this.isDriverAttached;
      if (!isUpdate) {
        data = await this.userService
          .addFleetUser(userdata).pipe(take(1)).toPromise();
      } else {
        if (this.isChangePassword) {
          userdata.isChangePassword = true;
        }
        data = await this.userService
          .updateFleetUser(this.userForm.controls.id.value, userdata).toPromise();
      }
      this.isLoading = false;
      if (data.userId) {
        if (!isUpdate) {
          const addUser = USER_TOAST_MESSAGES.add.addSuccess;
          this.messageService.add({ severity: 'success', summary: addUser.summary, detail: addUser.detail });
        } else {
          const updateUser = USER_TOAST_MESSAGES.update.addSuccess;
          this.messageService.add({ severity: 'success', summary: updateUser.summary, detail: updateUser.detail });
        }
        this.redirectToList();
      } else {
        const addUser = USER_TOAST_MESSAGES.add.addError;
        this.messageService.add({ severity: 'error', summary: addUser.summary, detail: addUser.detail });
      }

    } catch (e) {
      this.isLoading = false;
      const updateUser = USER_TOAST_MESSAGES.update.addError;
      this.messageService.add({ severity: 'error', summary: updateUser.summary, detail: e.error });
      console.error(e);
    }
  }

  // show fleet locations dialog
  showDialog() {
    this.display = true;
  }

  // hide fleet locations dialog
  hideDialog() {
    this.display = false;
  }

  // select all fleet locations
  selectAllFleetLocations(event) {
    this.fleetLocations.forEach((element) => {
      if (event) {
        element.isChecked = true;
      } else {
        element.isChecked = false;
      }
    });
  }

  // select single fleet location
  async selectLocation(event, id) {
    this.fleetLocations.forEach((element) => {
      if (element._id === id) {
        element.isChecked = event.target.checked;
      }
    });
  }

  // click on fleet checkbox.
  async fleetSelectCheckBox(id?, fleetName?) {
    if (id && id !== this.selectedFleetId) {
      this.selectedFleetId = id;
      this.selectedFleetName = fleetName;
      this.fleetLocations = [];
      this.fleetDetails.forEach((fleet) => {
        if (fleet._id === id) {
          fleet.isChecked = true;
          this.fleetLocations = fleet.location;
        }
        else { fleet.isChecked = false; }
      });
    }
    this.showDialog();
  }

  // redirect to userlist
  redirectToList() {
    this.route.navigate(['/users']);
  }

  // Function to update password
  changePassword() {
    this.isChangePassword = true;
    this.userForm.controls.password.setValue('');
    this.userForm.controls.confirmPassword.setValue('');
    this.userForm.controls.password.enable();
    this.userForm.controls.confirmPassword.enable();
  }

  // Function to cancel chnage password
  cancelChangePassword() {
    this.isChangePassword = false;
    this.userForm.controls.password.setValue('*****');
    this.userForm.controls.confirmPassword.setValue('*****');
    this.userForm.controls.password.disable();
    this.userForm.controls.confirmPassword.disable();
  }

}
