import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagedResponse, CrudBaseService } from '../../shared/services/crud-base.service';
import { DriverInfo } from './model/DriverInfo';

@Injectable({
  providedIn: 'root',
})
export class DriverService extends CrudBaseService<DriverInfo> {
  protected urlPath: string = 'driver';

  constructor(protected http: HttpClient) {
    super();
  }

  attachUser: any;
  getAttachedUserData() {
    return this.attachUser;
  }
  setAttachedUserData(attachUser: any) {
    this.attachUser = attachUser;
  }

  isUserAttached: boolean = false;
  getIsAttachedUser() {
    return this.isUserAttached;
  }
  setIsAttachedUser(isAttacheduser: boolean) {
    this.isUserAttached = isAttacheduser;
  }

  addDriver(model: DriverInfo) {
    return this.http.post(`${this.url}`, model);
  }

  driverListWithFleet() {
    return this.http.get(`${this.url}list`);
  }

  getDriverById(driverId) {
    return this.http.get(`${this.url}${driverId}`);
  }

  // getFleetDrivers(driverId) {
  //   // return this.http.get(`${this.url}fleets/list/${driverId}`);

  // }

  updateDriver(model: DriverInfo) {
    return this.http.put(`${this.url}${model._id}`, model);
  }

}
