import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CrudBaseService, PagedResponse } from '../../shared/services/crud-base.service';
import { VehicleInfo } from './model/Vehicle';

@Injectable({
  providedIn: 'root',
})
export class VehicleService extends CrudBaseService<VehicleInfo> {

  protected urlPath: string = 'vehicles';
  constructor(protected http: HttpClient) {
    super();
  }

  // TODO: Some of these routes need to be deleted
  // CrudBaseService handles list, get, update, delete
  getVehicleListWithLocation() {
    return this.http.get(`${this.url}locations`);
  }

  getVehicleList() {
    return this.http.get(`${this.url}list`);
  }

  getVehicleById(vehicleId) {
    return this.http.get(`${this.url}${vehicleId}`);
  }

  addVehicle(model: VehicleInfo) {
    return this.http.post(`${this.url}`, model);
  }

  updateVehicle(vehicleId: string, model: VehicleInfo) {
    return this.http.put(`${this.url}update/${vehicleId}`, model);
  }

  getAssociatedTrailler() {
    return this.http.get(`${this.url}trailer`);
  }

  getFleetVehicles(fleetId) {
    return this.http.get(`${this.url}vehicles/${fleetId}`);
  }

  getLocationVehicles(locationId) {
    return this.http.get(`${this.url}location/${locationId}`);
  }

}
