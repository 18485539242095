import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { appRoles } from 'src/app/core/constants';
import { AuthService } from '../../core/auth.service';

@Injectable({
  providedIn: 'root',
})
export class DriverGuard extends KeycloakAuthGuard implements CanActivate {

  constructor(protected router: Router, protected keycloakAngular: KeycloakService, private authService: AuthService) {
    super(router, keycloakAngular);
  }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!this.authenticated) {
        this.keycloakAngular.login().catch(e => console.error(e));
        return reject(false);
      }
      const userRoles: string[] = await this.keycloakAngular.getUserRoles();
      const isFleetUser: boolean = userRoles.includes(appRoles.stmEmployee);
      if (!isFleetUser) {
        this.authService.showUnauthorisedErrorMessages();
        resolve(false);
      }

      const requiredRoles: string[] = route.data.roles;
      if (!requiredRoles || requiredRoles.length === 0) {
        return resolve(true);
      }

      if (!this.roles || this.roles.length === 0) {
        resolve(false);
      }

      resolve(requiredRoles.every(role => this.roles.indexOf(role) > -1));
    });
  }
}
