import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutModule } from '../../layout/layout.module';
import { DriverInfoComponent } from './driver-info/driver-info.component';
import { DriverRoutingModule } from './driver-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { ListComponent } from './list/list.component';

@NgModule({
  declarations: [DriverInfoComponent, ListComponent],
  imports: [
    CommonModule,
    DriverRoutingModule,
    LayoutModule,
    SharedModule,
  ],
})
export class DriverModule { }
