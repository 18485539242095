<h1 style="text-align: center;"> Fleet Info</h1>
<div class="card">
</div>
<p-table #dt class="center" [value]="fleetItems" dataKey="id" [rows]="10"
  [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" styleClass="p-table-customers" [paginator]="true"
  [filterDelay]="0" [globalFilterFields]="['fleetName','fleetCity','fleetState']">
  <ng-template pTemplate="caption">
    <div style="text-align: right">
      <input type="text" pInputText size="50" placeholder="Search"
        (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:20vw">
      <a [routerLink]="['/fleet-info/addfleet']">
        <p-button label="Add Fleet" icon="fa fa-plus" class="ml" iconPos="left"></p-button>
      </a>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Fleet Name</th>
      <th>Address</th>
      <th>City</th>
      <th>State</th>
      <th>Action</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-fleet>
    <tr>
      <td>{{fleet.fleetName}} </td>
      <td>{{fleet.fleetAddress}} </td>
      <td>{{fleet.fleetCity}} </td>
      <td>{{fleet.fleetState}} </td>
      <td>
        <a title="View Location" (click)="showLocationModal(fleet)"><button pButton type="button" icon="fa fa-eye"
            class="p-button-text"></button></a>&nbsp;
        <a title="View Assigned Vehicles" (click)="showVehicleModal(fleet)"><button pButton type="button"
            icon="fa fa-truck" class="p-button-text"></button></a>&nbsp;
        <a title="View Assigned Drivers" (click)="showDrivers(fleet.id)"><button pButton type="button"
            icon="fa fa-id-card" class="p-button-text"></button></a>
        <a title="View/Edit Fleet Details" [routerLink]="['/fleet-info/addfleet/' + fleet.id]" class="icon">
          <button pButton type="button" icon="fa fa-pencil" class="p-button-text"></button>
        </a>
        <a (click)="$event.preventDefault(); openPopupDeleteFleet(fleet.id)">
          <button pButton type="button" icon="fa fa-trash" class="p-button-text"></button></a>
      </td>
    </tr>
    <p-dialog header="Delete Fleet" [(visible)]="deletePop" [modal]="true" [style]="{width: '40vw'}"
      [baseZIndex]="10000" [draggable]="false" [resizable]="false">
      <p>Please Confirm. Do you want to delete ?</p>
      <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-check" (click)="deleteFleetListByOne()" label="Yes"></button>
        <button type="button" pButton icon="pi pi-times" (click)="deletePop=false" label="No"
          class="p-button-secondary"></button>
      </ng-template>
    </p-dialog>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-fleet>
    <tr>
      <td colspan="5" class="no-record-msg">No fleet added yet..!!</td>
    </tr>
  </ng-template>
</p-table>
<p-dialog header="Location Already Selected !" [(visible)]="locationNotDelete" [modal]="true" [style]="{width: '40vw'}"
  [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <p style="text-align: center;">Fleet location already selected in user management field.</p>
</p-dialog>

<p-dialog #dtLocation [(visible)]="isLocation" [modal]="true" [style]="{width: '80vw'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false">
  <div>
    <p-table [value]="selectedData" [globalFilterFields]="['locationInfo','address','addressCity', 'addressState']"
      [paginator]="false" [scrollable]="true" scrollHeight="200px">

      <ng-template pTemplate="caption">
        <label type="button" class="text-left pull-left mt-1"> Fleet Locations
        </label>

        <input pInputText type="text" class="text-right pull-right"
          (input)="dtLocation.filterGlobal($event.target.value, 'contains')" placeholder="Search" />
        <br /><br>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th class="text-left">
            Location Info
          </th>
          <th class="text-left">
            Address
          </th>
          <th class="text-left">
            City
          </th>
          <th class="text-left">
            State
          </th>
          <th class="text-left">
            Contact Name
          </th>
          <th class="text-left">
            Contact Phone
          </th>
          <th class="text-left">
            Action
          </th>

        </tr>

      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td>{{data.locationInfo}}</td>
          <td>{{data.address}}</td>
          <td>{{data.addressCity}}</td>
          <td>{{data.addressState}}</td>
          <td>{{data.contactName}}</td>
          <td>{{data.contactPhone | mask: '(000) 000-0000'}}</td>
          <td>
            <a (click)="showLocationVehicleModel(data._id)">
              <button pButton type="button" icon="fa fa-truck" class="p-button-text"></button></a> &nbsp;
            <a (click)="showDriversByLocation(data._id)"><button pButton title="View Assigned Drivers" type="button"
                icon="fa fa-id-card" class="p-button-text"></button></a>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-data>
        <tr>
          <td colspan="5" class="no-record-msg">No fleet location assigned yet..!!</td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-grid">
      <div class="p-col-12 p-md-10">

      </div>
      <div class="p-col-12 p-md-2">
        <button pButton type="button" class="p-button-secondary pull-right" (click)="closeLocationModal()"
          label="Close"></button>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog #dtVehicle [(visible)]="isTrailer" [modal]="true" [style]="{width: '80vw'}" [baseZIndex]="10001"
  [draggable]="false" [resizable]="false">
  <div>
    <p-table [value]="vehicles" [globalFilterFields]="['locationInfo','address','addressCity', 'addressState']"
      [paginator]="false" [scrollable]="true" scrollHeight="200px">

      <ng-template pTemplate="caption">
        <label type="button" class="text-left pull-left mt-1"> Vehicle Details</label>
        <input pInputText type="text" class="text-right pull-right"
          (input)="dtLocation.filterGlobal($event.target.value, 'contains')" placeholder="Search" />
        <br /><br>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th class="text-left">
            Description
          </th>
          <th class="text-left">
            Unit
          </th>
          <th class="text-left">
            Tire Size
          </th>
          <th class="text-left">
            Tire Preference
          </th>
        </tr>

      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td>{{data.description}}</td>
          <td>{{data.units}}</td>
          <td>{{data.tireSize}}</td>
          <td>{{data.trailerTirePreference}}</td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-data>
        <tr>
          <td colspan="4" class="no-record-msg">No vehicle attached yet..!!</td>
        </tr>
      </ng-template>

    </p-table>
    <div class="p-grid">
      <div class="p-col-12 p-md-10">

      </div>
      <div class="p-col-12 p-md-2">
        <button pButton type="button" class="p-button-secondary pull-right" (click)="closeVehicleModal()"
          label="Close"></button>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog #dtDriver [(visible)]="showDriverList" [modal]="true" [style]="{width: '80vw'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false">
  <div>
    <p-table #dtDriver [value]="drivers" [globalFilterFields]="['name','phone','unit', 'notes']" [paginator]="false"
      [scrollable]="true" scrollHeight="200px">

      <ng-template pTemplate="caption">
        <label type="button" class="text-left pull-left mt-1"> Assigned Driver List</label>
        <input pInputText type="text" class="text-right pull-right"
          (input)="dtDriver.filterGlobal($event.target.value, 'contains')" placeholder="Search" />
        <br /><br>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th class="text-left">
            Driver Name
          </th>
          <th class="text-left">
            Phone
          </th>
          <th class="text-left">
            Unit
          </th>
          <th class="text-left">
            Notes
          </th>
        </tr>

      </ng-template>
      <ng-template pTemplate="body" let-driver>
        <tr>
          <td>{{driver.firstName}} {{driver.lastName}}</td>
          <td>{{driver.phone}}</td>
          <td>{{driver.unit}}</td>
          <td>{{driver.notes}}</td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-data>
        <tr>
          <td colspan="4" class="no-record-msg">No records found..!!</td>
        </tr>
      </ng-template>

    </p-table>
    <div class="p-grid">
      <div class="p-col-12 p-md-10">

      </div>
      <div class="p-col-12 p-md-2">
        <button pButton type="button" class="p-button-secondary pull-right" (click)="closeDriver()"
          label="Close"></button>
      </div>
    </div>
  </div>
</p-dialog>