import { Component, OnInit } from '@angular/core';
import { FleetInfoService } from '../fleet-info.service';
import { ToastService } from 'src/app/layout/toast.service';
import { VehicleInfo } from 'src/app/settings/vehicle-management/model/Vehicle';
import { VehicleService } from 'src/app/settings/vehicle-management/vehicle.service';
import { FleetInfo, LocationInfo } from '../fleet-info.interface';
import { UserService } from 'src/app/settings/user-management/user.service';
import { PagedResponse } from 'src/app/shared/services/crud-base.service';
import { DriverInfo } from 'src/app/settings/driver-management/model/DriverInfo';
import { DriverService } from 'src/app/settings/driver-management/driver.service';

@Component({
  selector: 'app-fleet-info-items',
  templateUrl: './fleet-info-items.component.html',
  styleUrls: ['./fleet-info-items.component.scss'],
})
export class FleetInfoItemsComponent implements OnInit {

  fleetItems: any = [];
  display = false;
  first = 0;
  rows = 10;
  deletePop = false;
  deleteId: number;
  locationNotDelete: boolean = false;
  isLocation: boolean = false;
  isTrailer: boolean = false;
  selectedData: any = [];
  vehicles: any = [];
  fleetLocations: LocationInfo[];
  selectedLocationId: string;

  showDriverList: boolean = false;
  drivers: DriverInfo[] = [];

  constructor(
    private fleetInfoService: FleetInfoService,
    private toastService: ToastService,
    private vehicleService: VehicleService,
    private driverService: DriverService,
    protected userService: UserService) { }

  ngOnInit() {
    this.getAllFleetList();
  }

  // get all fleet list
  getAllFleetList() {
    this.fleetInfoService.findMany().subscribe((data: any) => {
      this.fleetItems = data.documents.reverse();
    });
  }

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.fleetItems ? this.first === (this.fleetItems.length - this.rows) : true;
  }

  isFirstPage(): boolean {
    return this.fleetItems ? this.first === 0 : true;
  }

  async openPopupDeleteFleet(fleetId: number) {

    this.fleetInfoService.isFleetAlreadyAssigned(fleetId).subscribe((result) => {
      if (result.data) {
        this.toastService.add({
          severity: 'error',
          summary: 'Already Assigned', detail: result.msg,
        });
      } else {
        this.deletePop = true;
        this.deleteId = fleetId;
      }
    });
  }

  // Function to delete particular fleet
  deleteFleetListByOne(fleetId) {
    this.fleetInfoService.deleteFleet(this.deleteId).subscribe((res) => {
      if (res.status === 'success') {
        this.deletePop = false;
        this.getAllFleetList();
        this.toastService.add({ severity: 'success', summary: 'Deleted', detail: res.msg });
      }
    });
  }

  // Function to show location modal
  showVehicleModal(fleet: FleetInfo, locationId?: string) {
    this.selectedLocationId = locationId;
    this.isTrailer = true;
    this.fleetLocations = fleet.location;
    this.vehicleService.getFleetVehicles(fleet._id).subscribe((data: VehicleInfo[]) => {
      this.vehicles = data;
    });
  }

  showLocationModal(fleet: FleetInfo) {
    this.isLocation = true;
    this.selectedData = fleet.location;
  }

  showLocationVehicleModel(locationId) {
    this.isTrailer = true;
    this.vehicleService.getLocationVehicles(locationId).subscribe((data) => {
      this.vehicles = data;
    });
  }

  closeLocationModal() {
    this.isLocation = false;
  }

  showDrivers(fleetId) {
    this.showDriverList = true;
    const query = { pageSize: 'all', fleet: fleetId };
    this.driverService.findMany(query).subscribe((data) => {
      this.drivers = data.documents.reverse();
    });
  }

  showDriversByLocation(locationId) {
    this.showDriverList = true;
    const query = { pageSize: 'all', 'fleetLocations.locationId': locationId };
    this.driverService.findMany(query).subscribe((data) => {
      this.drivers = data.documents.reverse();
    });
  }

  closeVehicleModal() {
    this.isTrailer = false;
  }

  closeDriver() {
    this.showDriverList = false;
  }
}
