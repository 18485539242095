<form [formGroup]="searchForm" class="p-grid p-justify-center search-form" style="padding-top: 20px;">

  <div class="p-col-6">
    <app-super-input [autofocus]="'true'" (keyup)="onKeyUp($event)" icon="fas fa-building" label="Customer Name (Sort Name)" [fc]="searchForm.controls['sortName']" [tabIndex]="0">
    </app-super-input>
  </div>
  <div class="p-col-6">
    <app-super-input (keyup)="onKeyUp($event)" icon="fas fa-street-view" label="City, State" [fc]="searchForm.controls['address3']" [tabIndex]="1">
    </app-super-input>
  </div>

  <div class="p-col-4">
    <app-super-input (keyup)="onKeyUp($event)" icon="far fa-check-square" label="Exclude Vendor Customers (Y/N)" [fc]="searchForm.controls['excludeVendorCustomers']" maxlength="1" [tabIndex]="2">
    </app-super-input>
  </div>
  <div class="p-col-4">
    <app-super-input (keyup)="onKeyUp($event)" icon="fas fa-hashtag" label="Vendor Customer Number" [fc]="searchForm.controls['nationalAccountCustomerNumber']" [tabIndex]="3">
    </app-super-input>
  </div>
  <div class="p-col-4">
    <app-super-input (keyup)="onKeyUp($event)" icon="fas fa-hashtag" label="Vendor Number" [fc]="searchForm.controls['nationalAccountVendorNumber']" maxlength="3" [tabIndex]="4">
    </app-super-input>
  </div>

  <div class="p-col-6">
    <app-super-input (keyup)="onKeyUp($event)" icon="fas fa-hashtag" label="Location Code" [fc]="searchForm.controls['locationCode']" [tabIndex]="5">
    </app-super-input>
  </div>
  <div class="p-col-6">
    <app-super-input (keyup)="onKeyUp($event)" icon="fas fa-hashtag" label="Customer Number" [fc]="searchForm.controls['customerNumber']" [tabIndex]="6">
    </app-super-input>
  </div>

  <div class="p-col-6">
    <app-super-input (keyup)="onKeyUp($event)" icon="fas fa-building" label="Full Customer Name" [fc]="searchForm.controls['name']" [tabIndex]="7">
    </app-super-input>
  </div>
  <div class="p-col-6" style="text-align: right;">
    <button type="submit" (click)="customerSearch()" pButton icon="fa fa-search" label="Search [Enter]" [tabIndex]="8"></button>
  </div>
</form>

 

  
  <div class="p-col-12 loading-img" *ngIf="!searched && !searching">
    <div>
      <h3 class="empty-title">Start by searching for customers using the fields above.</h3>
      <img class="search-img" width="100px" src="assets/img/search.svg" />
    </div>
  </div>

  <div class="p-col-12 loading-img" *ngIf="searching">
    <img src="assets/img/searching_trucks.gif" />
  </div>

   <div class="p-col-12 loading-img" *ngIf="searched && !searching && customers?.length <= 0">
    <div>
        <h3 class="empty-title">Sorry we couldn't find any matches for you search criteria.</h3>
        <p>Maybe your search was too specific, please try searching with another term.</p>
        <img class="search-img" width="100px" src="assets/img/search_question.svg" />
    </div>
  </div>

  <div #result>
  <p-table *ngIf="!searching && searched && customers?.length > 0" [columns]="cols" [lazy]="true" [value]="customers" [paginator]="true" [rows]="pageSize"
    selectionMode="single" [totalRecords]="totalCustomerResults" (onLazyLoad)="paginate($event)"
    [(selection)]="selectedCustomer" dataKey="_id" [loading]="customersLoading" (onRowSelect)="onRowSelect($event)">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
          {{col.header}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData">
        <td *ngFor="let col of columns">
          {{rowData[col.field]}}
        </td>
      </tr>
    </ng-template>
  </p-table>
  </div>
