<div class="p-grid p-align-center customer-picker-content" [formGroup]="parentForm">
  <div class="p-col-6">
    <span [ngStyle]="!selectedCustomer ? { 'visibility': 'visible', 'position': 'relative' } : { 'visibility': 'hidden', 'position': 'absolute' }">
      <label class="auto-complete-label" for="float-input" [style.color]="invalid ? '#E50000' : 'grey'">Customer Account</label>
      <p-autoComplete
        [style]="{'width':'100%'}"
        appRequiredCornerTriangle
        inputId="customer-picker-input"
        [formControlName]="controlName"
        placeholder="Enter customer name, number or location code."
        [suggestions]="suggestedCustomers"
        field="customerNumberString"
        (onSelect)="onCustomerAutocompleteSelect($event)"
        (completeMethod)="customersAutocomplete($event)"
        (onClear)="clearCustomer()"
        [required]="true"
        [invalid]="(control.touched || submitted) && control.invalid"
        [ngClass]="{'invalid-auto-complete': (control.touched || submitted) && control.invalid}"
        >
        <ng-template let-customer pTemplate="item">
          <div class="p-helper-clearfix" style="border-bottom: 1px solid #f4f4f4;">
            <div class="customer-info" style="padding:10px">
              <div *ngIf="customer.customerNumber">Customer #: {{customer.customerNumber}}</div>
              <div *ngIf="customer.locationCode">Location #: {{customer.locationCode}}</div>
              <div *ngIf="customer.sortName">Sort Name: {{customer.sortName}}</div>
              <div *ngIf="customer.nationalAccountCustomerNumber">Vendor Customer #:
                {{customer.nationalAccountCustomerNumber}}</div>
            </div>
            <div style="margin:10px 10px 0 0">
              <div style="font-size:16px;"><i *ngIf="customer.search" style="margin-right:10px;"
                  class="fas fa-search"></i>{{customer.name}}</div>
              <div style="font-size:10px" *ngIf="customer.address1">{{customer.address1}}</div>
              <div style="font-size:10px" *ngIf="customer.address3">{{customer.address3}}</div>
            </div>
          </div>
        </ng-template>
      </p-autoComplete>
      <label *ngIf="(control.touched || submitted) && control.invalid" class="invalid-text">** Please select a customer.</label>
    </span>

    <p-card *ngIf="selectedCustomer">
      <div class="header">
        <div class="title">
          <h2>{{selectedCustomer?.name}}</h2>
        </div>
        <div class="clear-btn">
          <button *ngIf="!disabled" pButton type="button" class="p-button-secondary" label="Change" (click)="clearCustomer()"></button>
        </div>
      </div>
      <div class="p-helper-clearfix">
        <div class="customer-info">
          <div *ngIf="selectedCustomer?.customerNumber">Customer #: {{selectedCustomer?.customerNumber}}</div>
          <div *ngIf="selectedCustomer?.locationCode">Location #: {{selectedCustomer?.locationCode}}</div>
          <div *ngIf="selectedCustomer?.sortName">Sort Name: {{selectedCustomer?.sortName}}</div>
          <div *ngIf="selectedCustomer?.nationalAccountCustomerNumber">Vendor Customer #:
            {{selectedCustomer?.nationalAccountCustomerNumber}}</div>
        </div>
        <div class="address">
          <div style="font-size:10px" *ngIf="selectedCustomer?.address1">{{selectedCustomer?.address1}}</div>
          <div style="font-size:10px" *ngIf="selectedCustomer?.address3">{{selectedCustomer?.address3}}</div>
        </div>
      </div>
    </p-card>


  </div>
  <div class="p-lg-6 search-button" *ngIf="!disabled">
    <button pButton type="button" label="Advanced Customer Lookup" (click)="openCustomerLookupDialog()"
      class="p-button-raised customer-lookup-btn p-button-success">
      <div class="fa-2x customer-search-icon">
        <span class="fa-layers fa-fw">
          <i class="fa-inverse fas fa-search"></i>
        </span>
      </div>
    </button>
  </div>
</div>



<p-dialog header="Customer Lookup" icon="fas fa-search" [(visible)]="displayCustomerLookupDialog" [modal]="true"
  [style]="{width: '80%', minWidth: '200px'}" [minY]="40" [maximizable]="true" [baseZIndex]="10000">

  <app-customer-advanced-search (onCustomerSelect)="onCustomerSelect($event)"></app-customer-advanced-search>


</p-dialog>
